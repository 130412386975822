import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";

import * as EasiScoreActions from "../../redux/actions/easi-scores-actions";
import "react-h5-audio-player/lib/styles.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  AuditOutlined
} from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Button,
  Radio,
  Carousel,
  Modal,
  Form,
  Input,
  PageHeader,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import { MedicationIconSvg } from "../shared/svg/medication";
import Icon from "@ant-design/icons";
import { LabSvg } from "../shared/svg/labicon";
const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const MedicationIcon = (props) => (
  <Icon component={MedicationIconSvg} {...props} />
);
const ButtonGroup = Button.Group;

class EASILowerExt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientSelfEstimation: undefined,
      patientSelfEstimationJanssen: undefined,
      image: {},
      patientData: undefined,
      photoGallery: undefined,
      loading: true,
      submitted: false,
      patientVoiceDiary: [],
      selectItemId: "",
      selectedCol: "survey",
      selectedMonth: 1,
      selectedWeek: 1,
      selectedMonthNih: 1,
      partTypeNih: "UPPER_BODY",
      globalAssessment: undefined,
      submitAreaScore: false,
      month: 1,
      week: 1,
      monthnih: 1,
      range: "",
      edema: "",
      excoriation: "",
      induration: "",
      lichenification: "",
      ascoScope: "",
      erythema: "",
      scaling: "",
      edemaValue: undefined,
      excoriationsValue: undefined,
      erythemaValue: undefined,
      ascoScopeValue: undefined,
      indurationValue: undefined,
      lichenificationValue: undefined,
      scalingValue: undefined,
      selectedBodyPart: "Lower Ext",
      selectedBodyPartNih: "Lower_Limbs",
      bodypartTypeByWeek: "LOWER_BODY",
      partTypeNih: "LOWER_BODY",
      initialSlide: 0,
      areaScoreValue: undefined,
      isDisabled: false,
      currentImageIndex: 0,
      // rotationAngles: (props.photoGalleryByWeek && props.photoGalleryByWeek.length) ? props.photoGalleryByWeek.map(() => 0) : [],
      rotationAngleState: "",
      rotationAngles: Array(this.props.photoGalleryByWeek && this.props.photoGalleryByWeek.length).fill(0),
    };
  }

  async componentDidMount(prevProps, prevState) {
    const { history } = this.props;
    this.setState({ isDisabled: true });
    this.setState({ loading: true });
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          // loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    let month = this.state.month;
    let week = this.state.week;
    let partType = this.state.bodypartTypeByWeek;
    // await this.props.getPatientById(patientId);
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(patientId);
    } else if (studyId == 4){
      await this.props.getPatientByIdNih(patientId);
    } else {
      await this.props.getPatientById(patientId);
    }
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      // this.setState({ loading: false })
    }
    
    if (studyId == 1) {
      await this.props.getPhotoGalleryByWeek(
        1,
        month,
        patientId,
        "LOWER_EXTREMITY"
      );
      if (this.props.easiScores) {
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ photoGallery: this.props.photoGallery });
        this.setState({
          patientSelfEstimation: this.props.patientSelfEstimation,
          loading: false,
        });
      }
    } else if(studyId == 4) {
      let monthnih= this.state.monthnih;
      let partTypeNih = this.state.partTypeNih;
      let pasiScores = undefined;
      if (this.props.weekByParts && this.props.weekByParts.patId &&
        this.props.weekByParts.patId === patientId
      ) {
        await this.getPhotosNih(this.props.weekByParts.curMonth);
      } else {
        await this.props.getNihPhotoGallery(monthnih, partTypeNih, patientId, studyId, this.state.week);
        pasiScores = await this.props.getScoresNih(patientId,monthnih,"Lower_Limbs");
      }
      this.setState({ photoGallery: this.props.photoGalleryByWeek });
      
      if (pasiScores) {
        await this.setState({ easiScores: pasiScores && pasiScores.data, areaScoreValue: pasiScores && pasiScores.data && pasiScores.data.areaScore });
      }
      
      let localErythmaValue = localStorage.getItem("localErythmaValueLower"+this.state.monthnih+patientId);
      let localIndurationValue = localStorage.getItem("localIndurationValueLower"+this.state.monthnih+patientId);
      let localExcoriationValue = localStorage.getItem("localExcoriationValueLower"+this.state.monthnih+patientId);
      let localLichenificationValue = localStorage.getItem("localLichenificationValueLower"+this.state.monthnih+patientId);
      let localScalingValue = localStorage.getItem("localScalingValueLower"+this.state.monthnih+patientId);


      const patientData = this.props.patientData;

      if (patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS") {
        if (
          this.state.easiScores &&
          this.state.easiScores.scaling &&
          this.state.easiScores.scaling != null &&
          this.state.easiScores.scaling === "None" &&
          (localScalingValue === null || localScalingValue === undefined)
        ) {
          this.setState({ scalingValue: 0, scaling: "None" });
        } else if (localScalingValue === "None") {
          this.setState({ scalingValue: 0, scaling: "None" });
        }
    
        if (
          this.state.easiScores &&
          this.state.easiScores.scaling &&
          this.state.easiScores.scaling != null &&
          this.state.easiScores.scaling === "Slight" &&
          (localScalingValue === null || localScalingValue === undefined)
        ) {
          this.setState({ scalingValue: 1, scaling: "Slight" });
        } else if (localScalingValue === "Slight") {
          this.setState({ scalingValue: 1, scaling: "Slight" });
        }
    
        if (
          this.state.easiScores &&
          this.state.easiScores.scaling &&
          this.state.easiScores.scaling != null &&
          this.state.easiScores.scaling === "Moderate" &&
          (localScalingValue === null || localScalingValue === undefined)
        ) {
          this.setState({ scalingValue: 2, scaling: "Moderate" });
        } else if (localScalingValue === "Moderate") {
          this.setState({ scalingValue: 2, scaling: "Moderate" });
        }
    
        if (
          this.state.easiScores &&
          this.state.easiScores.scaling &&
          this.state.easiScores.scaling != null &&
          this.state.easiScores.scaling === "Severe" &&
          (localScalingValue === null || localScalingValue === undefined)
        ) {
          this.setState({ scalingValue: 3, scaling: "Severe" });
        } else if (localScalingValue === "Severe") {
          this.setState({ scalingValue: 3, scaling: "Severe" });
        }
    
        if (
          this.state.easiScores &&
          this.state.easiScores.scaling &&
          this.state.easiScores.scaling != null &&
          this.state.easiScores.scaling === "Very_Severe" &&
          (localScalingValue === null || localScalingValue === undefined)
        ) {
          this.setState({ scalingValue: 4, scaling: "Very_Severe" });
        } else if (localScalingValue === "Very_Severe") {
          this.setState({ scalingValue: 4, scaling: "Very_Severe" });
        }
        
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "None" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 0, erythema: "None" });
        } else if (localErythmaValue === "None") {
          this.setState({ erythemaValue: 0, erythema: "None" });
        }
    
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "Slight" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 1, erythema: "Slight" });
        } else if (localErythmaValue === "Slight") {
          this.setState({ erythemaValue: 1, erythema: "Slight" });
        }
  
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "Moderate" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 2, erythema: "Moderate" });
        } else if (localErythmaValue === "Moderate") {
          this.setState({ erythemaValue: 2, erythema: "Moderate" });
        }
  
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "Severe" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 3, erythema: "Severe" });
        } else if (localErythmaValue === "Severe") {
          this.setState({ erythemaValue: 3, erythema: "Severe" });
        }
    
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "Very_Severe" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 4,  erythema: "Very_Severe" });
        } else if (localErythmaValue === "Very_Severe") {
          this.setState({ erythemaValue: 4, erythema: "Very_Severe" });
        }
  
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "None" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 0, induration: "None" });
        } else if (localIndurationValue === "None") {
          this.setState({ indurationValue: 0, induration: "None" });
        }
    
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "Slight" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 1, induration: "Slight" });
        } else if (localIndurationValue === "Slight") {
          this.setState({ indurationValue: 1, induration: "Slight" });
        }
  
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "Moderate" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 2, induration: "Moderate" });
        } else if (localIndurationValue === "Moderate") {
          this.setState({ indurationValue: 2, induration: "Moderate" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "Severe" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 3, induration: "Severe" });
        } else if (localIndurationValue === "Severe") {
          this.setState({ indurationValue: 3, induration: "Severe" });
        }
    
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "Very_Severe" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 4, induration: "Very_Severe" });
        } else if (localIndurationValue === "Very_Severe") {
          this.setState({ indurationValue: 4, induration: "Very_Severe" });
        }
      } else {
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "None" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 0, erythema: "None" });
        } else if (localErythmaValue === "None") {
          this.setState({ erythemaValue: 0, erythema: "None" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "Mild" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 1, erythema: "Mild" });
        } else if (localErythmaValue === "Mild") {
          this.setState({ erythemaValue: 1, erythema: "Mild" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "Mild_Moderate" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 1.5, erythema: "Mild_Moderate" });
        } else if (localErythmaValue === "Mild_Moderate") {
          this.setState({ erythemaValue: 1.5, erythema: "Mild_Moderate" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "Moderate" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 2, erythema: "Moderate" });
        } else if (localErythmaValue === "Moderate") {
          this.setState({ erythemaValue: 2, erythema: "Moderate" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "Moderate_Severe" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 2.5, erythema: "Moderate_Severe" });
        } else if (localErythmaValue === "Moderate_Severe") {
          this.setState({ erythemaValue: 2.5, erythema: "Moderate_Severe" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.erythema &&
          this.state.easiScores.erythema != null &&
          this.state.easiScores.erythema === "Severe" &&
          (localErythmaValue === null || localErythmaValue === undefined)
        ) {
          this.setState({ erythemaValue: 3, erythema: "Severe" });
        } else if (localErythmaValue === "Severe") {
          this.setState({ erythemaValue: 3, erythema: "Severe" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.excoriation &&
          this.state.easiScores.excoriation != null &&
          this.state.easiScores.excoriation === "None" && 
          (localExcoriationValue === null || localExcoriationValue === undefined)
        ) {
          this.setState({ excoriationsValue: 0, excoriation: "None" });
        } else if (localExcoriationValue === "None") {
          this.setState({ excoriationsValue: 0, excoriation: "None" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.excoriation &&
          this.state.easiScores.excoriation != null &&
          this.state.easiScores.excoriation === "Mild" && 
          (localExcoriationValue === null || localExcoriationValue === undefined)
        ) {
          this.setState({ excoriationsValue: 1, excoriation: "Mild" });
        } else if (localExcoriationValue === "Mild") {
    
          this.setState({ excoriationsValue: 1, excoriation: "Mild" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.excoriation &&
          this.state.easiScores.excoriation != null &&
          this.state.easiScores.excoriation === "Mild_Moderate" && 
          (localExcoriationValue === null || localExcoriationValue === undefined)
        ) {
          this.setState({ excoriationsValue: 1.5, excoriation: "Mild_Moderate" });
        } else if (localExcoriationValue === "Mild_Moderate") {
    
          this.setState({ excoriationsValue: 1.5, excoriation: "Mild_Moderate" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.excoriation &&
          this.state.easiScores.excoriation != null &&
          this.state.easiScores.excoriation === "Moderate" && 
          (localExcoriationValue === null || localExcoriationValue === undefined)
        ) {
          this.setState({ excoriationsValue: 2, excoriation: "Moderate" });
        } else if (localExcoriationValue === "Moderate") {
          this.setState({ excoriationsValue: 2, excoriation: "Moderate" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.excoriation &&
          this.state.easiScores.excoriation != null &&
          this.state.easiScores.excoriation === "Moderate_Severe" && 
          (localExcoriationValue === null || localExcoriationValue === undefined)
        ) {
          this.setState({ excoriationsValue: 2.5, excoriation: "Moderate_Severe" });
        } else if (localExcoriationValue === "Moderate_Severe") {
          this.setState({ excoriationsValue: 2.5, excoriation: "Moderate_Severe" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.excoriation &&
          this.state.easiScores.excoriation != null &&
          this.state.easiScores.excoriation === "Severe" && 
          (localExcoriationValue === null || localExcoriationValue === undefined)
        ) {
          this.setState({ excoriationsValue: 3, excoriation: "Severe" });
        } else if (localExcoriationValue === "Severe") {
          this.setState({ excoriationsValue: 3, excoriation: "Severe" });
        }
  
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "None" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 0, induration: "None" });
        } else if (localIndurationValue === "None") {
          this.setState({ indurationValue: 0, induration: "None" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "Mild" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 1, induration: "Mild" });
        } else if (localIndurationValue === "Mild") {
          this.setState({ indurationValue: 1, induration: "Mild" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "Mild_Moderate" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 1.5, induration: "Mild_Moderate" });
        } else if (localIndurationValue === "Mild_Moderate") {
          this.setState({ indurationValue: 1.5, induration: "Mild_Moderate" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "Moderate" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 2, induration: "Moderate" });
        } else if (localIndurationValue === "Moderate") {
          this.setState({ indurationValue: 2, induration: "Moderate" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "Moderate_Severe" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 2.5, induration: "Moderate_Severe" });
        } else if (localIndurationValue === "Moderate_Severe") {
          this.setState({ indurationValue: 2.5, induration: "Moderate_Severe" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.induration &&
          this.state.easiScores.induration != null &&
          this.state.easiScores.induration === "Severe" &&
          (localIndurationValue === null || localIndurationValue === undefined)
        ) {
          this.setState({ indurationValue: 3, induration: "Severe" });
        } else if (localIndurationValue === "Severe") {
          this.setState({ indurationValue: 3, induration: "Severe" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.lichenification &&
          this.state.easiScores.lichenification != null &&
          this.state.easiScores.lichenification === "None" &&
          (localLichenificationValue === null || localLichenificationValue === undefined)
        ) {
          this.setState({ lichenificationValue: 0, lichenification: "None" });
        } else if (localLichenificationValue === "None") {
          this.setState({ lichenificationValue: 0, lichenification: "None" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.lichenification &&
          this.state.easiScores.lichenification != null &&
          this.state.easiScores.lichenification === "Mild" &&
          (localLichenificationValue === null || localLichenificationValue === undefined)
        ) {
          this.setState({ lichenificationValue: 1, lichenification: "Mild" });
        } else if (localLichenificationValue === "Mild") {
          this.setState({ lichenificationValue: 1, lichenification: "Mild" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.lichenification &&
          this.state.easiScores.lichenification != null &&
          this.state.easiScores.lichenification === "Mild_Moderate" &&
          (localLichenificationValue === null || localLichenificationValue === undefined)
        ) {
          this.setState({ lichenificationValue: 1.5, lichenification: "Mild_Moderate" });
        } else if (localLichenificationValue === "Mild_Moderate") {
          this.setState({ lichenificationValue: 1.5, lichenification: "Mild_Moderate" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.lichenification &&
          this.state.easiScores.lichenification != null && 
          this.state.easiScores.lichenification === "Moderate" &&
          (localLichenificationValue === null || localLichenificationValue === undefined)
        ) {
          this.setState({ lichenificationValue: 2, lichenification: "Moderate" });
        } else if (localLichenificationValue === "Moderate") {
          this.setState({ lichenificationValue: 2, lichenification: "Moderate" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.lichenification &&
          this.state.easiScores.lichenification != null && 
          this.state.easiScores.lichenification === "Moderate_Severe" &&
          (localLichenificationValue === null || localLichenificationValue === undefined)
        ) {
          this.setState({ lichenificationValue: 2.5, lichenification: "Moderate_Severe" });
        } else if (localLichenificationValue === "Moderate_Severe") {
          this.setState({ lichenificationValue: 2.5, lichenification: "Moderate_Severe" });
        }
        if (
          this.state.easiScores &&
          this.state.easiScores.lichenification &&
          this.state.easiScores.lichenification != null &&
          this.state.easiScores.lichenification === "Severe" &&
          (localLichenificationValue === null || localLichenificationValue === undefined)
        ) {
          this.setState({ lichenificationValue: 3, lichenification: "Severe" }); 
        } else if (localLichenificationValue === "Severe") {
          this.setState({ lichenificationValue: 3, lichenification: "Severe" });
    
        }
      }
      



    } else {
      if (this.props.weekByParts && this.props.weekByParts != undefined) {
        await this.getPhotosByWeek(this.props.weekByParts);
        // await this.props.getAllImagesAndScoreForPhysicianPortal(studyId, this.props.weekByParts, patientId, partType);
        this.setState({selectedWeek: this.props.weekByParts})
      } else {
        await this.props.getAllImagesAndScoreForPhysicianPortal(studyId, this.state.week, patientId, partType);
      }
      // await this.props.getAllImagesAndScoreForPhysicianPortal(
      //   studyId,
      //   week,
      //   patientId,
      //   partType
      // );


      if (this.props.easiScores) {
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({
          patientSelfEstimation: this.props.patientSelfEstimationJanssen,
          loading: false,
        });
      }
      if (this.props.easiScores && (this.props.easiScores.rangeMaxVal || this.props.easiScores.rangeMaxVal == 0)){
        this.setState({areaScoreValue: this.props.easiScores && this.props.easiScores.rangeMaxVal && this.props.easiScores.rangeMaxVal.toString()})
      }
    }

    let localErythmaValue = localStorage.getItem("localErythmaValueLower"+this.state.week+patientId);
    let localIndurationValue = localStorage.getItem("localErythmaValueLower"+this.state.week+patientId);
    let localExcoriationValue = localStorage.getItem("localErythmaValueLower"+this.state.week+patientId);
    let localLichenificationValue = localStorage.getItem("localErythmaValueLower"+this.state.week+patientId);

    
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "None" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 0 });
    } else if (localErythmaValue === "None") {
      this.setState({ erythemaValue: 0, erythema: "None" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Mild" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 1 });
    } else if (localErythmaValue === "Mild") {
      this.setState({ erythemaValue: 1, erythema: "Mild" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Mild_Moderate" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 1.5 });
    } else if (localErythmaValue === "Mild_Moderate") {
      this.setState({ erythemaValue: 1.5, erythema: "Mild_Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Moderate" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 2 });
    } else if (localErythmaValue === "Moderate") {
      this.setState({ erythemaValue: 2, erythema: "Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Moderate_Severe" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 2.5 });
    } else if (localErythmaValue === "Moderate_Severe") {
      this.setState({ erythemaValue: 2.5, erythema: "Moderate_Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Severe" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 3 });
    } else if (localErythmaValue === "Severe") {
      this.setState({ erythemaValue: 3, erythema: "Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.edema &&
      this.props.easiScores.edema != null &&
      this.props.easiScores.edema === "None"
    ) {
      this.setState({ edemaValue: 0 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.edema &&
      this.props.easiScores.edema != null &&
      this.props.easiScores.edema === "Mild"
    ) {
      this.setState({ edemaValue: 1 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.edema &&
      this.props.easiScores.edema != null &&
      this.props.easiScores.edema === "Moderate"
    ) {
      this.setState({ edemaValue: 2 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.edema &&
      this.props.easiScores.edema != null &&
      this.props.easiScores.edema === "Severe"
    ) {
      this.setState({ edemaValue: 3 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "None" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 0 });
    } else if (localExcoriationValue === "None") {
      this.setState({ excoriationsValue: 0, excoriation: "None" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Mild" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 1 });
    } else if (localExcoriationValue === "Mild") {

      this.setState({ excoriationsValue: 1, excoriation: "Mild" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Mild_Moderate" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 1.5 });
    } else if (localExcoriationValue === "Mild_Moderate") {

      this.setState({ excoriationsValue: 1.5, excoriation: "Mild_Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Moderate" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 2 });
    } else if (localExcoriationValue === "Moderate") {
      this.setState({ excoriationsValue: 2, excoriation: "Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Moderate_Severe" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 2.5 });
    } else if (localExcoriationValue === "Moderate_Severe") {
      this.setState({ excoriationsValue: 2.5, excoriation: "Moderate_Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Severe" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 3 });
    } else if (localExcoriationValue === "Severe") {
      this.setState({ excoriationsValue: 3, excoriation: "Severe" });
    }

    if (
      this.props.easiScores &&
      this.props.easiScores.ascoScope &&
      this.props.easiScores.ascoScope != null &&
      this.props.easiScores.ascoScope === "None"
    ) {
      this.setState({ ascoScopeValue: 0 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.ascoScope &&
      this.props.easiScores.ascoScope != null &&
      this.props.easiScores.ascoScope === "Mild"
    ) {
      this.setState({ ascoScopeValue: 1 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.ascoScope &&
      this.props.easiScores.ascoScope != null &&
      this.props.easiScores.ascoScope === "Moderate"
    ) {
      this.setState({ ascoScopeValue: 2 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.ascoScope &&
      this.props.easiScores.ascoScope != null &&
      this.props.easiScores.ascoScope === "Severe"
    ) {
      this.setState({ ascoScopeValue: 3 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "None" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 0 });
    } else if (localIndurationValue === "None") {
      this.setState({ indurationValue: 0, induration: "None" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Mild" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 1 });
    } else if (localIndurationValue === "Mild") {
      this.setState({ indurationValue: 1, induration: "Mild" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Mild_Moderate" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 1.5 });
    } else if (localIndurationValue === "Mild_Moderate") {
      this.setState({ indurationValue: 1.5, induration: "Mild_Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Moderate" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 2 });
    } else if (localIndurationValue === "Moderate") {
      this.setState({ indurationValue: 2, induration: "Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Moderate_Severe" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 2.5 });
    } else if (localIndurationValue === "Moderate_Severe") {
      this.setState({ indurationValue: 2.5, induration: "Moderate_Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Severe" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 3 });
    } else if (localIndurationValue === "Severe") {
      this.setState({ indurationValue: 3, induration: "Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null &&
      this.props.easiScores.lichenification === "None" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 0 });
    } else if (localLichenificationValue === "None") {
      this.setState({ lichenificationValue: 0, lichenification: "None" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null &&
      this.props.easiScores.lichenification === "Mild" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 1 });
    } else if (localLichenificationValue === "Mild") {
      this.setState({ lichenificationValue: 1, lichenification: "Mild" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null &&
      this.props.easiScores.lichenification === "Mild_Moderate" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 1.5 });
    } else if (localLichenificationValue === "Mild_Moderate") {
      this.setState({ lichenificationValue: 1.5, lichenification: "Mild_Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null && 
      this.props.easiScores.lichenification === "Moderate" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 2 });
    } else if (localLichenificationValue === "Moderate") {
      this.setState({ lichenificationValue: 2, lichenification: "Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null && 
      this.props.easiScores.lichenification === "Moderate_Severe" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 2.5 });
    } else if (localLichenificationValue === "Moderate_Severe") {
      this.setState({ lichenificationValue: 2.5, lichenification: "Moderate_Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null &&
      this.props.easiScores.lichenification === "Severe" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 3 }); 
    } else if (localLichenificationValue === "Severe") {
      this.setState({ lichenificationValue: 3, lichenification: "Severe" });

    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
    this.setState({submitted: false, submitAreaScore: false, loading: false});
    this.setState({isDisabled: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.photoGalleryByWeek !== this.props.photoGalleryByWeek) {
      this.setState({
        rotationAngles: this.props.photoGalleryByWeek && this.props.photoGalleryByWeek.length ? this.props.photoGalleryByWeek.map(() => 0) : [],
      });
    }
  }

  async componentWillReceiveProps(nextProps) {
    let studyId = localStorage.getItem("studyId")
    if (this.props.easiScores !== nextProps.easiScores) {
      this.setState({ easiScores: nextProps.easiScores });
      this.setState({ photoGallery: nextProps.photoGallery });
      if (studyId == 1) {
        this.setState({ patientSelfEstimation: nextProps.patientSelfEstimation });
      } else {
        this.setState({ patientSelfEstimation: nextProps.patientSelfEstimationJanssen });
      }
      this.setState({ loading: false });
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  goToHead = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };
  goToUpperExt = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-upperExt/" + patientId);
  };
  goToLowerExt = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-lowerExt/" + patientId);
  };
  goToTrunk = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-trunk/" + patientId);
  };

  goToReviewTotal = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-total/" + patientId);
  };

  goToGlobalAssessment = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/globalAssessment/" + patientId);
  };

  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  redirect(path) {
    window.open(path);
  }

  async getPhotos(month) {
    var patientId = this.props.match.params.id;
    this.setState({ selectedMonth: month, month: month, loading: true });
    await this.props.getPhotoGalleryByWeek(
      1,
      month,
      patientId,
      "LOWER_EXTREMITY"
    );
    if (this.props.easiScores) {
      this.setState({ photoGallery: this.props.photoGallery });
      this.setState({ easiScores: this.props.easiScores });
      this.setState({
        patientSelfEstimation: this.props.patientSelfEstimation,
        loading: false,
      });
    }
  }

  async getPhotosByWeek(week) {
    this.setState({ isDisabled: true });
    this.setState({submitAreaScore: true});
    this.setState({areaScoreValue: undefined});
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let partType = this.state.bodypartTypeByWeek
    this.setState({ selectedWeek: week, week: week, loading: true });
    await this.props.getAllImagesAndScoreForPhysicianPortal(
      studyId,
      week,
      patientId,
      "LOWER_BODY",
    );
    if (this.props.easiScores) {
      this.setState({ photoGallery: this.props.photoGalleryByWeek });
      this.setState({ easiScores: this.props.easiScores });
      await this.props.getLocalState(week);
      this.setState({
        patientSelfEstimation: this.props.patientSelfEstimationJanssen,
        areaScoreValue: this.props.easiScores && this.props.easiScores.rangeMaxVal && this.props.easiScores.rangeMaxVal.toString(),
        loading: false,
      });
      this.setState({
        erythema: this.props.easiScores.erythema,
        induration: this.props.easiScores.induration,
        excoriation: this.props.easiScores.excoriation,
        lichenification: this.props.easiScores.lichenification
      });
    }
    let localErythmaValue = localStorage.getItem("localErythmaValueLower"+this.state.week+patientId);
    let localIndurationValue = localStorage.getItem("localIndurationValueLower"+this.state.week+patientId);
    let localExcoriationValue = localStorage.getItem("localExcoriationValueLower"+this.state.week+patientId);
    let localLichenificationValue = localStorage.getItem("localLichenificationValueLower"+this.state.week+patientId)

    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "None" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 0 });
    } else if (localErythmaValue === "None") {
      this.setState({ erythemaValue: 0, erythema: "None" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Mild" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 1 });
    } else if (localErythmaValue === "Mild") {
      this.setState({ erythemaValue: 1, erythema: "Mild" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Mild_Moderate" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 1.5 });
    } else if (localErythmaValue === "Mild_Moderate") {
      this.setState({ erythemaValue: 1.5, erythema: "Mild_Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Moderate" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 2 });
    } else if (localErythmaValue === "Moderate") {
      this.setState({ erythemaValue: 2, erythema: "Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Moderate_Severe" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 2.5 });
    } else if (localErythmaValue === "Moderate_Severe") {
      this.setState({ erythemaValue: 2.5, erythema: "Moderate_Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.erythema &&
      this.props.easiScores.erythema != null &&
      this.props.easiScores.erythema === "Severe" &&
      (localErythmaValue === null || localErythmaValue === undefined)
    ) {
      this.setState({ erythemaValue: 3 });
    } else if (localErythmaValue === "Severe") {
      this.setState({ erythemaValue: 3, erythema: "Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.edema &&
      this.props.easiScores.edema != null &&
      this.props.easiScores.edema === "None"
    ) {
      this.setState({ edemaValue: 0 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.edema &&
      this.props.easiScores.edema != null &&
      this.props.easiScores.edema === "Mild"
    ) {
      this.setState({ edemaValue: 1 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.edema &&
      this.props.easiScores.edema != null &&
      this.props.easiScores.edema === "Moderate"
    ) {
      this.setState({ edemaValue: 2 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.edema &&
      this.props.easiScores.edema != null &&
      this.props.easiScores.edema === "Severe"
    ) {
      this.setState({ edemaValue: 3 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "None" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 0 });
    } else if (localExcoriationValue === "None") {
      this.setState({ excoriationsValue: 0, excoriation: "None" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Mild" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 1 });
    } else if (localExcoriationValue === "Mild") {

      this.setState({ excoriationsValue: 1, excoriation: "Mild" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Mild_Moderate" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 1.5 });
    } else if (localExcoriationValue === "Mild_Moderate") {

      this.setState({ excoriationsValue: 1.5, excoriation: "Mild_Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Moderate" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 2 });
    } else if (localExcoriationValue === "Moderate") {
      this.setState({ excoriationsValue: 2, excoriation: "Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Moderate_Severe" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 2.5 });
    } else if (localExcoriationValue === "Moderate_Severe") {
      this.setState({ excoriationsValue: 2.5, excoriation: "Moderate_Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.excoriation &&
      this.props.easiScores.excoriation != null &&
      this.props.easiScores.excoriation === "Severe" && 
      (localExcoriationValue === null || localExcoriationValue === undefined)
    ) {
      this.setState({ excoriationsValue: 3 });
    } else if (localExcoriationValue === "Severe") {
      this.setState({ excoriationsValue: 3, excoriation: "Severe" });
    }

    if (
      this.props.easiScores &&
      this.props.easiScores.ascoScope &&
      this.props.easiScores.ascoScope != null &&
      this.props.easiScores.ascoScope === "None"
    ) {
      this.setState({ ascoScopeValue: 0 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.ascoScope &&
      this.props.easiScores.ascoScope != null &&
      this.props.easiScores.ascoScope === "Mild"
    ) {
      this.setState({ ascoScopeValue: 1 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.ascoScope &&
      this.props.easiScores.ascoScope != null &&
      this.props.easiScores.ascoScope === "Moderate"
    ) {
      this.setState({ ascoScopeValue: 2 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.ascoScope &&
      this.props.easiScores.ascoScope != null &&
      this.props.easiScores.ascoScope === "Severe"
    ) {
      this.setState({ ascoScopeValue: 3 });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "None" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 0 });
    } else if (localIndurationValue === "None") {
      this.setState({ indurationValue: 0, induration: "None" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Mild" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 1 });
    } else if (localIndurationValue === "Mild") {
      this.setState({ indurationValue: 1, induration: "Mild" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Mild_Moderate" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 1.5 });
    } else if (localIndurationValue === "Mild_Moderate") {
      this.setState({ indurationValue: 1.5, induration: "Mild_Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Moderate" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 2 });
    } else if (localIndurationValue === "Moderate") {
      this.setState({ indurationValue: 2, induration: "Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Moderate_Severe" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 2.5 });
    } else if (localIndurationValue === "Moderate_Severe") {
      this.setState({ indurationValue: 2.5, induration: "Moderate_Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.induration &&
      this.props.easiScores.induration != null &&
      this.props.easiScores.induration === "Severe" &&
      (localIndurationValue === null || localIndurationValue === undefined)
    ) {
      this.setState({ indurationValue: 3 });
    } else if (localIndurationValue === "Severe") {
      this.setState({ indurationValue: 3, induration: "Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null &&
      this.props.easiScores.lichenification === "None" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 0 });
    } else if (localLichenificationValue === "None") {
      this.setState({ lichenificationValue: 0, lichenification: "None" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null &&
      this.props.easiScores.lichenification === "Mild" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 1 });
    } else if (localLichenificationValue === "Mild") {
      this.setState({ lichenificationValue: 1, lichenification: "Mild" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null &&
      this.props.easiScores.lichenification === "Mild_Moderate" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 1.5 });
    } else if (localLichenificationValue === "Mild_Moderate") {
      this.setState({ lichenificationValue: 1.5, lichenification: "Mild_Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null && 
      this.props.easiScores.lichenification === "Moderate" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 2 });
    } else if (localLichenificationValue === "Moderate") {
      this.setState({ lichenificationValue: 2, lichenification: "Moderate" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null && 
      this.props.easiScores.lichenification === "Moderate_Severe" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 2.5 });
    } else if (localLichenificationValue === "Moderate_Severe") {
      this.setState({ lichenificationValue: 2.5, lichenification: "Moderate_Severe" });
    }
    if (
      this.props.easiScores &&
      this.props.easiScores.lichenification &&
      this.props.easiScores.lichenification != null &&
      this.props.easiScores.lichenification === "Severe" &&
      (localLichenificationValue === null || localLichenificationValue === undefined)
    ) {
      this.setState({ lichenificationValue: 3 }); 
    } else if (localLichenificationValue === "Severe") {
      this.setState({ lichenificationValue: 3, lichenification: "Severe" });

    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
    this.setState({submitAreaScore: false});
    this.setState({loading: false});
    this.setState({ isDisabled: false });
  }
  

  saveEASIHeadScores = async (values) => {
    var patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId")
    let localErythmaValue = localStorage.getItem("localErythmaValueLower"+this.state.week+patientId);
    let localIndurationValue = localStorage.getItem("localIndurationValueLower"+this.state.week+patientId);
    let localExcoriationValue = localStorage.getItem("localExcoriationValueLower"+this.state.week+patientId);
    let localLichenificationValue = localStorage.getItem("localLichenificationValueLower"+this.state.week+patientId);

    if (
      // (this.state.ascoScope === "" && this.props.easiScores.ascoScope === "") ||
      // (this.state.Edema === "" && this.props.easiScores.edema === "") ||
      // (this.state.Excoriations === "" &&
      //   this.props.easiScores.excoriation === "") ||
      // (this.state.Erythema === "" && this.props.easiScores.erythema === "")
      (this.state.ascoScope === "" && this.props.easiScores.ascoScope === "") ||
      (this.state.edema === "" && this.props.easiScores.edema === "") ||
      (this.state.excoriation === "" &&
        this.props.easiScores.excoriation === "") ||
      (this.state.erythema === "" && this.props.easiScores.erythema === "") || 
      (this.state.induration === "" && this.props.easiScores.induration === "") || 
      (this.state.lichenification === "" && this.props.easiScores.lichenification === "")
    ) {
      notifyUser("Please add lower ext. scores", "error");
    } else {
      let areaScore = 0;
      if (studyId == 1) {
        if (this.state.patientSelfEstimation &&
          this.state.patientSelfEstimation.rangeMaxVal) {
            if (this.state.patientSelfEstimation.rangeMaxVal === "0") {
              areaScore = 0;
            }
            if (this.state.patientSelfEstimation.rangeMaxVal === "-1") {
              areaScore = 1;
            }
            if (
              this.state.patientSelfEstimation.rangeMaxVal === "1" ||
              this.state.patientSelfEstimation.rangeMaxVal === "2"
            ) {
              areaScore = 2;
            }
            if (
              this.state.patientSelfEstimation.rangeMaxVal === "3" ||
              this.state.patientSelfEstimation.rangeMaxVal === "4"
            ) {
              areaScore = 3;
            }
            if (
              this.state.patientSelfEstimation.rangeMaxVal === "5" ||
              this.state.patientSelfEstimation.rangeMaxVal === "6"
            ) {
              areaScore = 4;
            }
            if (
              this.state.patientSelfEstimation.rangeMaxVal === "7" ||
              this.state.patientSelfEstimation.rangeMaxVal === "8"
            ) {
              areaScore = 5;
            }
            if (
              this.state.patientSelfEstimation.rangeMaxVal === "9" ||
              this.state.patientSelfEstimation.rangeMaxVal === "10"
            ) {
              areaScore = 6;
            }
          }
      } else {
        if (this.state.areaScoreValue === "0") {
          areaScore = 0;
        }
        if (
          this.state.areaScoreValue === "1") {
          areaScore = 1;
        }
        if (
          this.state.areaScoreValue === "2"){
          areaScore = 2;
        }
        if (
          this.state.areaScoreValue === "3" ||
          this.state.areaScoreValue === "4") {
          areaScore = 3;
        }
        if (
          this.state.areaScoreValue === "5" ||
          this.state.areaScoreValue === "6") {
          areaScore = 4;
        }
        if (
          this.state.areaScoreValue === "7" ||
          this.state.areaScoreValue === "8") {
          areaScore = 5;
        }
        if (
          this.state.areaScoreValue === "9" ||
          this.props.easiScores.rangeMaxVal === "9"
        ) {
          areaScore = 6;
        }

      }



      this.setState({ submitted: true });

      let data = {
        ...(studyId == 1 && {
          ascoScope:
          this.state.ascoScope !== ""
            ? this.state.ascoScope
            : this.props.easiScores.ascoScope,
        edema:
          this.state.edema !== ""
            ? this.state.edema
            : this.props.easiScores.edema,
        erythema:
          this.state.erythema !== ""
            ? this.state.erythema
            : this.props.easiScores.erythema,
        excoriation:
          this.state.excoriation !== ""
            ? this.state.excoriation
            : this.props.easiScores.excoriation,
            totalScore: (
              Math.round(
                ((
                  this.state.edemaValue +
                  this.state.excoriationsValue +
                  this.state.erythemaValue +
                  this.state.ascoScopeValue ) *
                  areaScore *
                  0.4 *
                  1000) /
                10
              ) / 100
            ).toFixed(2),
            month: this.state.month,
            week: this.state.week,
            patientId: patientId,
            partType: "LOWER_EXTREMITY",
        }),
       ...(studyId == 2  && {
        erythema: this.state.erythema !== "" ? 
                  this.state.erythema:
                  this.props.easiScores.erythema,
        excoriation:  this.state.excoriation !== "" ? 
                  this.state.excoriation: 
                  this.props.easiScores.excoriation,
        induration: this.state.induration !== ""  ? 
                  this.state.induration:
                  this.props.easiScores.induration,
        lichenification:  this.state.lichenification !== "" ? 
                  this.state.lichenification: 
                  this.props.easiScores.lichenification,
        totalScore: (
              Math.round(
                ((
                  this.state.excoriationsValue +
                  this.state.erythemaValue +
                  this.state.indurationValue +
                  this.state.lichenificationValue) *
                  areaScore *
                  0.4 *
                  1000) /
                10
              ) / 100
            ).toFixed(2),
            month: this.state.month,
            week: this.state.week,
            patientId: patientId,
            partType: "LOWER_BODY",
            areaScore: areaScore,
            studyId: studyId, 
            rangeMaxVal: this.state.areaScoreValue && this.state.areaScoreValue.toString(),
          }),        
      };

    if (studyId === 1) {
      if (
        this.state.patientSelfEstimation &&
        this.state.patientSelfEstimation.rangeMaxVal) {
          await this.props.addEASIHeadScores(data);
          if (this.props.easiHeadScores) {
            this.setState({ easiScores: this.props.easiHeadScores });
            this.setState({ submitted: false });
            notifyUser("EASI Scores saved!", "success");
          }     
      } 
      else {
        this.setState({ submitted: false });
        notifyUser("Self Estimation Scores are not added by patient", "error");
      }
    } else {
      let savedData = await this.props.addEASIHeadScores(data);
      if (savedData == "" || savedData == undefined || savedData.data == "Total Score does not match") {
        notifyUser("EASI score not successfully saved due to missing values", "error");
      } else {
        this.setState(
          { easiScores: this.props.easiHeadScores, submitted: false },
          () => {
            notifyUser("Data Saved", "success");
          }
        );
      }
    }

    
    // Get all keys from localStorage
    const allKeys = Object.keys(localStorage);

    // Terms to check for
    const termsToRemove = ["localErythma", "localExcoriation", "localInduration", "localLichenification"];

    // Iterate through keys and remove those containing the specified terms
    allKeys.forEach(key => {
        if (termsToRemove.some(term => key.includes(term))) {
            localStorage.removeItem(key);
        }
    });
    this.setState({ submitted: false });
  }
  };

  savePasiScore = async (values) => {
    var patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    let patientData = this.props.patientData;
    if (
      (this.state.scaling === "" && this.state.easiScores.scaling === "") ||
      (this.state.excoriation === "" &&
        this.state.easiScores.excoriation === "") ||
      (this.state.erythema === "" && this.state.easiScores.erythema === "") || 
      (this.state.induration === "" && this.state.easiScores.induration === "") || 
      (this.state.lichenification === "" && this.state.easiScores.lichenification === "")
    ) 
    {
      notifyUser("Please add head scores", "error");
    } else {
      let areaScore = this.state.areaScoreValue;
      
      this.setState({ submitted: true });
      let data = {
        erythema: this.state.erythema !== ""?
                  this.state.erythema :
                  this.props.easiScores.erythema,
        scaling: this.state.scaling !== ""?
                    this.state.scaling :
                    this.props.easiScores.scaling,
        induration: this.state.induration !== "" ?
                  this.state.induration :
                  this.props.easiScores.induration,
        excoriation: this.state.excoriation !== "" ?
                  this.state.excoriation :
                  this.props.easiScores.excoriation,
        lichenification: this.state.lichenification !== "" ?
                  this.state.lichenification :
                  this.props.easiScores.lichenification,
        totalScore: patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS" ?
                (Math.round(
                    ((
                      this.state.scalingValue +
                      this.state.erythemaValue +
                      this.state.indurationValue) *
                      areaScore *
                      0.4 *
                      1000) /
                    10
                  ) / 100
                ).toFixed(2) :
                (Math.round(
                  ((this.state.erythemaValue +
                    this.state.indurationValue +
                    this.state.excoriationsValue +
                    this.state.lichenificationValue) *
                    areaScore *
                    0.4 *
                    1000) /
                  10
                ) / 100
              ).toFixed(2),
                month: this.state.monthnih,
                week: this.state.week,
                patientId: patientId,
                bodyPartType: "Lower_Limbs",
                studyId: studyId,
                areaScore: areaScore,
      }
      const savedData = await this.props.savePasiScore(data);
      if (savedData == "" || savedData == undefined || savedData.data == "Total Score does not match" || savedData.data == undefined) {
        notifyUser("EASI score not successfully saved due to missing values", "error");
        // Get all keys from localStorage
        const allKeys = Object.keys(localStorage);

        // Terms to check for
        const termsToRemove = ["localErythma", "localExcoriation", "localInduration", "localLichenification", "localScaling"];

        // Iterate through keys and remove those containing the specified terms
        allKeys.forEach(key => {
            if (termsToRemove.some(term => key.includes(term))) {
                localStorage.removeItem(key);
            }
        });
        
      } else {
        this.setState(
          { easiScores: this.props.easiHeadScores, submitted: false },
          () => {
            notifyUser("Data Saved", "success");
          }
        );
      }
      // }
        // Get all keys from localStorage
        const allKeys = Object.keys(localStorage);

        // Terms to check for
        const termsToRemove = ["localErythma", "localExcoriation", "localInduration", "localLichenification", "localScaling"];

        // Iterate through keys and remove those containing the specified terms
        allKeys.forEach(key => {
            if (termsToRemove.some(term => key.includes(term))) {
                localStorage.removeItem(key);
            }
        });

          this.setState({ submitted: false });
    }
  };

async getPhotosNih(monthnih) {
  await this.setState({
    erythema: "",
    induration: "",
    excoriation: "",
    scaling: "",
    lichenification: "",
    erythemaValue: undefined,
    indurationValue: undefined,
    excoriationsValue: undefined,
    scalingValue: undefined,
    lichenificationValue: undefined,
  })
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let partTypeNih = this.state.partTypeNih;
    let data = {
      patId: patientId,
      curMonth: monthnih
    }
    await this.props.getLocalState(data);
    this.setState({ selectedMonthNih: monthnih, monthnih: monthnih, loading: true });
    await this.props.getNihPhotoGallery(monthnih, partTypeNih, patientId, studyId, this.state.week);
    this.setState({ photoGallery: this.props.photoGalleryByWeek });


    let localErythmaValue = localStorage.getItem("localErythmaValueLower"+monthnih+patientId);
    let localIndurationValue = localStorage.getItem("localIndurationValueLower"+monthnih+patientId);
    let localExcoriationValue = localStorage.getItem("localExcoriationValueLower"+monthnih+patientId);
    let localLichenificationValue = localStorage.getItem("localLichenificationValueLower"+monthnih+patientId);
    let localScalingValue = localStorage.getItem("localScalingValueLower"+monthnih+patientId);

    let pasiScores = await this.props.getScoresNih(patientId,monthnih,"Lower_Limbs")
    if (pasiScores) {
      this.setState({ easiScores: pasiScores && pasiScores.data, areaScoreValue: pasiScores && pasiScores.data && pasiScores.data.areaScore });
    }
    
    const patientData = this.props.patientData;

    if (patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS") {
      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "None" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 0, scaling: "None" });
      } else if (localScalingValue === "None") {
        this.setState({ scalingValue: 0, scaling: "None" });
      }
  
      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "Slight" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 1, scaling: "Slight" });
      } else if (localScalingValue === "Slight") {
        this.setState({ scalingValue: 1, scaling: "Slight" });
      }
  
      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "Moderate" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 2, scaling: "Moderate" });
      } else if (localScalingValue === "Moderate") {
        this.setState({ scalingValue: 2, scaling: "Moderate" });
      }
  
      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "Severe" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 3, scaling: "Severe" });
      } else if (localScalingValue === "Severe") {
        this.setState({ scalingValue: 3, scaling: "Severe" });
      }
  
      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "Very_Severe" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 4, scaling: "Very_Severe" });
      } else if (localScalingValue === "Very_Severe") {
        this.setState({ scalingValue: 4, scaling: "Very_Severe" });
      }
      
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "None" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 0, erythema: "None" });
      } else if (localErythmaValue === "None") {
        this.setState({ erythemaValue: 0, erythema: "None" });
      }
  
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Slight" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 1, erythema: "Slight" });
      } else if (localErythmaValue === "Slight") {
        this.setState({ erythemaValue: 1, erythema: "Slight" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Moderate" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 2, erythema: "Moderate" });
      } else if (localErythmaValue === "Moderate") {
        this.setState({ erythemaValue: 2, erythema: "Moderate" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Severe" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 3, erythema: "Severe" });
      } else if (localErythmaValue === "Severe") {
        this.setState({ erythemaValue: 3, erythema: "Severe" });
      }
  
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Very_Severe" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 4,  erythema: "Very_Severe" });
      } else if (localErythmaValue === "Very_Severe") {
        this.setState({ erythemaValue: 4, erythema: "Very_Severe" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "None" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 0, induration: "None" });
      } else if (localIndurationValue === "None") {
        this.setState({ indurationValue: 0, induration: "None" });
      }
  
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Slight" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 1, induration: "Slight" });
      } else if (localIndurationValue === "Slight") {
        this.setState({ indurationValue: 1, induration: "Slight" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Moderate" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 2, induration: "Moderate" });
      } else if (localIndurationValue === "Moderate") {
        this.setState({ indurationValue: 2, induration: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Severe" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 3, induration: "Severe" });
      } else if (localIndurationValue === "Severe") {
        this.setState({ indurationValue: 3, induration: "Severe" });
      }
  
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Very_Severe" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 4, induration: "Very_Severe" });
      } else if (localIndurationValue === "Very_Severe") {
        this.setState({ indurationValue: 4, induration: "Very_Severe" });
      }
    } else {
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "None" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 0, erythema: "None" });
      } else if (localErythmaValue === "None") {
        this.setState({ erythemaValue: 0, erythema: "None" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Mild" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 1, erythema: "Mild" });
      } else if (localErythmaValue === "Mild") {
        this.setState({ erythemaValue: 1, erythema: "Mild" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Mild_Moderate" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 1.5, erythema: "Mild_Moderate" });
      } else if (localErythmaValue === "Mild_Moderate") {
        this.setState({ erythemaValue: 1.5, erythema: "Mild_Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Moderate" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 2, erythema: "Moderate" });
      } else if (localErythmaValue === "Moderate") {
        this.setState({ erythemaValue: 2, erythema: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Moderate_Severe" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 2.5, erythema: "Moderate_Severe" });
      } else if (localErythmaValue === "Moderate_Severe") {
        this.setState({ erythemaValue: 2.5, erythema: "Moderate_Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Severe" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 3, erythema: "Severe" });
      } else if (localErythmaValue === "Severe") {
        this.setState({ erythemaValue: 3, erythema: "Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "None" && 
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 0, excoriation: "None" });
      } else if (localExcoriationValue === "None") {
        this.setState({ excoriationsValue: 0, excoriation: "None" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Mild" && 
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 1, excoriation: "Mild" });
      } else if (localExcoriationValue === "Mild") {
  
        this.setState({ excoriationsValue: 1, excoriation: "Mild" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Mild_Moderate" && 
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 1.5, excoriation: "Mild_Moderate" });
      } else if (localExcoriationValue === "Mild_Moderate") {
  
        this.setState({ excoriationsValue: 1.5, excoriation: "Mild_Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Moderate" && 
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 2, excoriation: "Moderate" });
      } else if (localExcoriationValue === "Moderate") {
        this.setState({ excoriationsValue: 2, excoriation: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Moderate_Severe" && 
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 2.5, excoriation: "Moderate_Severe" });
      } else if (localExcoriationValue === "Moderate_Severe") {
        this.setState({ excoriationsValue: 2.5, excoriation: "Moderate_Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Severe" && 
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 3, excoriation: "Severe" });
      } else if (localExcoriationValue === "Severe") {
        this.setState({ excoriationsValue: 3, excoriation: "Severe" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "None" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 0, induration: "None" });
      } else if (localIndurationValue === "None") {
        this.setState({ indurationValue: 0, induration: "None" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Mild" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 1, induration: "Mild" });
      } else if (localIndurationValue === "Mild") {
        this.setState({ indurationValue: 1, induration: "Mild" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Mild_Moderate" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 1.5, induration: "Mild_Moderate" });
      } else if (localIndurationValue === "Mild_Moderate") {
        this.setState({ indurationValue: 1.5, induration: "Mild_Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Moderate" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 2, induration: "Moderate" });
      } else if (localIndurationValue === "Moderate") {
        this.setState({ indurationValue: 2, induration: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Moderate_Severe" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 2.5, induration: "Moderate_Severe" });
      } else if (localIndurationValue === "Moderate_Severe") {
        this.setState({ indurationValue: 2.5, induration: "Moderate_Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Severe" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 3, induration: "Severe" });
      } else if (localIndurationValue === "Severe") {
        this.setState({ indurationValue: 3, induration: "Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "None" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 0, lichenification: "None" });
      } else if (localLichenificationValue === "None") {
        this.setState({ lichenificationValue: 0, lichenification: "None" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "Mild" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 1, lichenification: "Mild" });
      } else if (localLichenificationValue === "Mild") {
        this.setState({ lichenificationValue: 1, lichenification: "Mild" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "Mild_Moderate" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 1.5, lichenification: "Mild_Moderate" });
      } else if (localLichenificationValue === "Mild_Moderate") {
        this.setState({ lichenificationValue: 1.5, lichenification: "Mild_Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null && 
        this.state.easiScores.lichenification === "Moderate" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 2, lichenification: "Moderate" });
      } else if (localLichenificationValue === "Moderate") {
        this.setState({ lichenificationValue: 2, lichenification: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null && 
        this.state.easiScores.lichenification === "Moderate_Severe" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 2.5, lichenification: "Moderate_Severe" });
      } else if (localLichenificationValue === "Moderate_Severe") {
        this.setState({ lichenificationValue: 2.5, lichenification: "Moderate_Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "Severe" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 3, lichenification: "Severe" }); 
      } else if (localLichenificationValue === "Severe") {
        this.setState({ lichenificationValue: 3, lichenification: "Severe" });
  
      }
    }

    await this.setState({ loading: false});
  }

  updateScores = async (range, estimationId) => {
    this.setState({ submitAreaScore: true });
    // this.setState({ submitted: true })
    // let data = {
    //   estimationId: estimationId,
    //   rangeMaxVal: range,
    //   studyId: 1,
    // };
    // console.log('frst datadata', data);
    //Update EASi Scores
    var patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");

    if (
      // (this.state.ascoScope === "" && this.props.easiScores.ascoScope === "") ||
      // (this.state.Edema === "" && this.props.easiScores.edema === "") ||
      // (this.state.Excoriations === "" &&
      //   this.props.easiScores.excoriation === "") ||
      // (this.state.Erythema === "" && this.props.easiScores.erythema === "")
      (this.state.ascoScope === "" && this.props.easiScores.ascoScope === "") ||
      (this.state.edema === "" && this.props.easiScores.edema === "") ||
      (this.state.excoriation === "" &&
        this.props.easiScores.excoriation === "") ||
      (this.state.erythema === "" && this.props.easiScores.erythema === "") || 
      (this.state.induration === "" && this.props.easiScores.induration === "") || 
      (this.state.lichenification === "" && this.props.easiScores.lichenification === "")
    ) {
      notifyUser("Please add head scores", "error");
    } else {
      let areaScore = 0;
      if (
        this.state.patientSelfEstimation &&
        this.state.patientSelfEstimation.rangeMaxVal
      ) {
        if (this.state.patientSelfEstimation.rangeMaxVal === "0") {
          areaScore = 0;
        }
        if (this.state.patientSelfEstimation.rangeMaxVal === "-1") {
          areaScore = 1;
        }
        if (
          this.state.patientSelfEstimation.rangeMaxVal === "1" ||
          this.state.patientSelfEstimation.rangeMaxVal === "2"
        ) {
          areaScore = 2;
        }
        if (
          this.state.patientSelfEstimation.rangeMaxVal === "3" ||
          this.state.patientSelfEstimation.rangeMaxVal === "4"
        ) {
          areaScore = 3;
        }
        if (
          this.state.patientSelfEstimation.rangeMaxVal === "5" ||
          this.state.patientSelfEstimation.rangeMaxVal === "6"
        ) {
          areaScore = 4;
        }
        if (
          this.state.patientSelfEstimation.rangeMaxVal === "7" ||
          this.state.patientSelfEstimation.rangeMaxVal === "8"
        ) {
          areaScore = 5;
        }
        if (
          this.state.patientSelfEstimation.rangeMaxVal === "9" ||
          this.state.patientSelfEstimation.rangeMaxVal === "10"
        ) {
          areaScore = 6;
        }
      }
      // this.setState({ submitAreaScore: true });
      // let data = {
      //   estimationId: estimationId,
      //   rangeMaxVal: range,
      //   studyId: 1,
      //   ascoScope:
      //     this.state.ascoScope !== ""
      //       ? this.state.ascoScope
      //       : this.props.easiScores.ascoScope,
      //   edema:
      //     this.state.edema !== ""
      //       ? this.state.edema
      //       : this.props.easiScores.edema,
      //   erythema:
      //     this.state.erythema !== ""
      //       ? this.state.erythema
      //       : this.props.easiScores.erythema,
      //   excoriation:
      //     this.state.excoriation !== ""
      //       ? this.state.excoriation
      //       : this.props.easiScores.excoriation,
      //   totalScore: (
      //     Math.round(
      //       ((this.state.edemaValue +
      //         this.state.excoriationsValue +
      //         this.state.erythemaValue +
      //         this.state.ascoScopeValue) *
      //         areaScore *
      //         0.1 *
      //         1000) /
      //       10
      //     ) / 100
      //   ).toFixed(2),
      //   month: this.state.month,
      //   week: 1,
      //   patientId: patientId,
      //   partType: "HEAD",
      // };
      let data = {
        ...(studyId == 1 && {
          estimationId: estimationId,
          rangeMaxVal: range,
          studyId: 1,
          ascoScope:
          this.state.ascoScope !== ""
            ? this.state.ascoScope
            : this.props.easiScores.ascoScope,
        edema:
          this.state.edema !== ""
            ? this.state.edema
            : this.props.easiScores.edema,
        erythema:
          this.state.erythema !== ""
            ? this.state.erythema
            : this.props.easiScores.erythema,
        excoriation:
          this.state.excoriation !== ""
            ? this.state.excoriation
            : this.props.easiScores.excoriation,
            totalScore: (
              Math.round(
                ((
                  this.state.edemaValue +
                  this.state.excoriationsValue +
                  this.state.erythemaValue +
                  this.state.ascoScopeValue ) *
                  areaScore *
                  0.4 *
                  1000) /
                10
              ) / 100
            ).toFixed(2),
            month: this.state.month,
            week: this.state.week,
            patientId: patientId,
            partType: "LOWER_EXTREMITY",
        }),
       ...(studyId == 2  && {
        estimationId: estimationId,
        rangeMaxVal: range,
        studyId: 2,
        erythema:
          this.state.erythema !== ""
            ? this.state.erythema
            : this.props.easiScores.erythema,
        excoriation:
          this.state.excoriation !== ""
            ? this.state.excoriation
            : this.props.easiScores.excoriation,
        induration:
            this.state.induration !== ""
              ? this.state.induration
              : this.props.easiScores.induration,
        lichenification:
              this.state.lichenification !== ""
                ? this.state.lichenification
                : this.props.easiScores.lichenification,
        totalScore: (
          Math.round(
            ((
              this.state.excoriationsValue +
              this.state.erythemaValue +
              this.state.indurationValue +
              this.state.lichenificationValue) *
              areaScore *
              0.4 *
              1000) /
            10
          ) / 100
        ).toFixed(2),
        month: this.state.month,
        week: this.state.week,
        patientId: patientId,
        partType: "LOWER_BODY",
        
       }),       
      };
      if (
        this.state.patientSelfEstimation &&
        this.state.patientSelfEstimation.rangeMaxVal && studyId == 1
      ) {
        await this.props.addEASIHeadScores(data);
        if (this.props.easiHeadScores) {
          this.setState({ easiScores: this.props.easiHeadScores });
          // this.setState({ submitted: false });
          //notifyUser("EASI head scores added successfully", "success")
        }
        await this.props.updateSelfEstimationScores(data);
        if (this.props.updatePatientSelfEstimation) {
          notifyUser(
            "Patient's Self estimation scores are updated successfully",
            "success"
          );
          this.setState({ submitAreaScore: false });
        }
      } else if (this.state.patientSelfEstimation &&
        this.state.patientSelfEstimation.rangeMaxVal && studyId == 2) {
        await this.props.updateSelfEstimationScores(data);
        if (this.props.updatePatientSelfEstimation) {
          notifyUser(
            "Patient's Self estimation scores are updated successfully",
            "success"
          );
          this.setState({ submitAreaScore: false });
        }
      }
      else {
        this.setState({ submitAreaScore: false });
        notifyUser("Self Estimation Scores are not added by patient", "error");
      }
    }
    this.setState({ submitAreaScore: false });
  };
  
  updateRange = async (range) => {
    this.setState({ range: range });
  };
  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  showModalOth = (img, index) => {
    this.setState({
      isModalVisible: true,
      currentImageIndex: index,
      image: img,
    });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false, rotationAngleState: "",rotationAngles : Array(this.props.photoGalleryByWeek && this.props.photoGalleryByWeek.length).fill(0)  });
  };

  handleErythemaValueChange = (value) => {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    if (studyId === "4") {
      localStorage.setItem("localErythmaValueLower"+this.state.monthnih+patientId, value);
    } else {
      localStorage.setItem("localErythmaValueLower"+this.state.week+patientId, value);
    }
    this.setState({erythema: value});
  }

  handleIndurationValueChange = (value) => {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    if (studyId === "4") {
      localStorage.setItem("localIndurationValueLower"+this.state.monthnih+patientId, value);
    } else {
      localStorage.setItem("localIndurationValueLower"+this.state.week+patientId, value);
    }    
    this.setState({induration: value});
  }

  handleExcoriationValueChange = (value) => {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    if (studyId === "4") {
      localStorage.setItem("localExcoriationValueLower"+this.state.monthnih+patientId, value);
    } else {
      localStorage.setItem("localExcoriationValueLower"+this.state.week+patientId, value);
    }
    this.setState({excoriation: value});
  }
  handleScalingValueChange = (value) => {
    let patientId = this.props.match.params.id;
    localStorage.setItem("localScalingValueLower"+this.state.monthnih+patientId, value);
    this.setState({scaling: value});
  }
  handleLichenificationValueChange = (value) => {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    if (studyId === "4") {
      localStorage.setItem("localLichenificationValueLower"+this.state.monthnih+patientId, value);
    } else {
      localStorage.setItem("localLichenificationValueLower"+this.state.week+patientId, value);
    }
    this.setState({lichenification: value});
  }

  onChangeAreaScore = (e) => {
    this.setState({areaScoreValue: e.target.value})
  }

  // rotateImage = (index, direction) => {
  //   this.setState((prevState) => {
  //     const newAngles = [...prevState.rotationAngles];
  //     newAngles[index] += direction === 'left' ? -90 : 90;
  //     return { rotationAngles: newAngles };
  //   });
  // };

  rotateImage = (index, direction) => {
    this.setState((prevState) => {
      const newAngles = [...prevState.rotationAngles];
      let newRotationAngle = newAngles[index] + (direction === 'left' ? -90 : 90);
  
      // Normalize the angle to stay within the range [0, 360]
      if (newRotationAngle < 0) {
        newRotationAngle += 360;
      } else if (newRotationAngle >= 360) {
        newRotationAngle -= 360;
      }
  
      // Update the rotation angle in the state
      newAngles[index] = newRotationAngle;
      this.setState({rotationAngleState: newRotationAngle})
  
      // Log the current rotation position for the image
      console.log(`Image at index ${index} is rotated to ${newRotationAngle} degrees`);
  
      return { rotationAngles: newAngles };
    });
  };

  goToNewMed = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-medication/" + patientId);
  }

  render() {
    const {
      submitted,
      selectedCol,
      selectedMonth,
      selectedWeek,
      photoGallery,
      loading,
      selectedBodyPart,
      easiScores,
      patientSelfEstimation,
      submitAreaScore,
      selectedBodyPartNih,
      selectedScaling,
      edemaValue,
      excoriationsValue,
      erythemaValue,
      ascoScopeValue,
      edema,
      erythema,
      ascoScope,
      excoriation,
      initialSlide,
      currentImageIndex,
      rotationAngles,
      selectedMonthNih,
      partTypeNih,
      monthnih,
      rotationAngleState
    } = this.state;

    const { photoGalleryByWeek} = this.props;
    const patientData = this.props.patientData;

    const patientDataHealth = this.props.patientData && this.props.patientData[0];




    var reviewStandards = Config.reviewStandard.url;

    var patientId = this.props.match.params.id;
    let week = this.state.week;
    let weekNumber;

    switch (week) {
      case 1:
        weekNumber = 0;
        break;
      case 3:
        weekNumber = 2;
        break;
      case 5:
        weekNumber = 4;
        break;
      case 7:
        weekNumber = 6;
        break;
      case 9:
        weekNumber = 8;
        break;
      default:
        weekNumber = 0; // handle the case when week is not 1, 2, 3, or 4
        break;
    }

    let selectedErythma = localStorage.getItem("localErythmaValueLower"+this.state.week+patientId);
    let selectedInduration = localStorage.getItem("localIndurationValueLower"+this.state.week+patientId);
    let selectedExcoriation = localStorage.getItem("localExcoriationValueLower"+this.state.week+patientId);
    let selectedLichenification = localStorage.getItem("localLichenificationValueLower"+this.state.week+patientId);


    let localErythmaValue = localStorage.getItem("localErythmaValueLower"+this.state.monthnih+patientId);
    let localIndurationValue = localStorage.getItem("localIndurationValueLower"+this.state.monthnih+patientId);
    let localExcoriationValue = localStorage.getItem("localExcoriationValueLower"+this.state.monthnih+patientId);
    let localLichenificationValue = localStorage.getItem("localLichenificationValueLower"+this.state.monthnih+patientId);
    let localScalingValue = localStorage.getItem("localScalingValueLower"+this.state.monthnih+patientId);
    
    const studyId = localStorage.getItem("studyId");

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                this.state.data &&
                  this.state.data.title 
              }
            ></PageHeader>
            <Row gutter={16}>
              <Col xs={24} sm={12} md={6} xl={6}>
                <div className="white-box user-section">
                  <div className="user-profile">
                    <Link to={"/patient-progress/" + this.props.match.params.id} >
                      <Avatar size={84}>
                        {this.props.patientData &&
                          this.props.patientData[0].firstName
                            .toUpperCase()
                            .charAt(0) +
                          this.props.patientData[0].lastName
                            .toUpperCase()
                            .charAt(0)}
                      </Avatar>
                    </Link>
                    <div className="profile-details">
                      <Link to={"/patient-progress/" + this.props.match.params.id} >
                        <h3>
                          {this.props.patientData &&
                            this.props.patientData[0].firstName +
                            " " +
                            this.props.patientData[0].lastName}
                        </h3>
                      </Link>
                      {
                        studyId == 1 ?
                        <button className="btn" onClick={() => this.goToChat()}>
                          Chat Now
                        </button>
                        :
                        ""
                      }
                    </div>
                  </div>
                  <div className="user-details">
                    <List
                      style={{ marginTop: "40px" }}
                      itemLayout="horizontal"
                      dataSource={this.props.patientData}
                      renderItem={(patient) => (
                        <>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Study:
                            </Typography.Text>{" "}
                            {patient && patient.title}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Email:
                            </Typography.Text>{" "}
                            {patient.email}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Date of Birth:
                            </Typography.Text>{" "}
                            {/* {patient.dateOfBirth ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "DD-MM-YYYY"
                                  ) : "N/A"} */}
                                  {finalDate}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Age:
                            </Typography.Text>{" "}
                            {patient.age ? patient.age + " years" : "N/A"}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Phone Number:
                            </Typography.Text>{" "}
                            {"(" +
                              patient.phoneCode +
                              ") " +
                              patient.phoneNumber}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Member Status:
                            </Typography.Text>{" "}
                            {patient.userScreeningStatus.replace("_", " ")}
                          </List.Item>
                          {
                            studyId == 2 ?
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Patient's TimeZone:
                              </Typography.Text>{" "}
                                {patient.notificationTimezone}
                            </List.Item>
                            : ""
                          }
                          {
                            studyId == 2 ?
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Notifications Time:
                              </Typography.Text>{" "}
                                {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                            </List.Item>
                            : 
                            ""
                          }
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Registered Date:
                            </Typography.Text>{" "}
                            {patient.userStudyCreatedDate
                              ? moment(patient.userStudyCreatedDate)
                                .format(dateFormathh)
                              : "N/A"}
                          </List.Item>
                        </>
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={18} xl={18}>
              {studyId == 1 ? 
                <ButtonGroup className="horizontal-button-tabs">
                  <Button
                    className={selectedMonth === 1 ? "active" : ""}
                    onClick={() => this.getPhotos(1)}
                  >
                    Month 1
                  </Button>
                  <Button
                    className={selectedMonth === 2 ? "active" : ""}
                    onClick={() => this.getPhotos(2)}
                  >
                    Month 2
                  </Button>
                  <Button
                    className={selectedMonth === 3 ? "active" : ""}
                    onClick={() => this.getPhotos(3)}
                  >
                    Month 3
                  </Button>
                  <Button
                    className={selectedMonth === 4 ? "active" : ""}
                    onClick={() => this.getPhotos(4)}
                  >
                    Month 4
                  </Button>
                  <Button
                    className={selectedMonth === 5 ? "active" : ""}
                    onClick={() => this.getPhotos(5)}
                  >
                    Month 5
                  </Button>
                  <Button
                    className={selectedMonth === 6 ? "active" : ""}
                    onClick={() => this.getPhotos(6)}
                  >
                    Month 6
                  </Button>
                </ButtonGroup>
                : studyId === "4" ?
                <ButtonGroup className="horizontal-button-tabs spaces">
                  <Button
                    className={selectedMonthNih === 1 ? "active" : ""}
                    onClick={() => this.getPhotosNih(1)}
                  >
                    Month 0
                  </Button>
                  <Button
                    className={selectedMonthNih === 2 ? "active" : ""}
                    onClick={() => this.getPhotosNih(2)}
                  >
                    Month 1
                  </Button>
                  <Button
                    className={selectedMonthNih === 3 ? "active" : ""}
                    onClick={() => this.getPhotosNih(3)}
                  >
                    Month 2
                  </Button>
                  <Button
                    className={selectedMonthNih === 4 ? "active" : ""}
                    onClick={() => this.getPhotosNih(4)}
                  >
                    Month 3
                  </Button>
                  <Button
                    className={selectedMonthNih === 5 ? "active" : ""}
                    onClick={() => this.getPhotosNih(5)}
                  >
                    Month 4
                  </Button>
                  <Button
                    className={selectedMonthNih === 6 ? "active" : ""}
                    onClick={() => this.getPhotosNih(6)}
                  >
                    Month 5
                  </Button>
                  <Button
                    className={selectedMonthNih === 7 ? "active" : ""}
                    onClick={() => this.getPhotosNih(7)}
                  >
                    Month 6
                  </Button>
                </ButtonGroup>
                : 
                <ButtonGroup className="horizontal-button-tabs spaces">
                  <Button
                    className={selectedWeek === 1 ? "active" : ""}
                    onClick={() => this.getPhotosByWeek(1)}
                    disabled={this.state.isDisabled}
                  >
                    Week 0
                  </Button>  
                  <Button
                    className={selectedWeek === 3 ? "active" : ""}
                    onClick={() => this.getPhotosByWeek(3)}
                    disabled={this.state.isDisabled}
                  >
                    Week 2
                  </Button>  
                  <Button
                    className={selectedWeek === 5 ? "active" : ""}
                    onClick={() => this.getPhotosByWeek(5)}
                    disabled={this.state.isDisabled}
                  >
                    Week 4
                  </Button>  
                  <Button
                    className={selectedWeek === 7 ? "active" : ""}
                    onClick={() => this.getPhotosByWeek(7)}
                    disabled={this.state.isDisabled}
                  >
                    Week 6
                  </Button>  
                  <Button
                    className={selectedWeek === 9 ? "active" : ""}
                    onClick={() => this.getPhotosByWeek(9)}
                    disabled={this.state.isDisabled}
                  >
                    Week 8
                  </Button> 
                </ButtonGroup>
                }
                  <ButtonGroup className="horizontal-button-tabs spaces">
                    <Button
                      onClick={() => this.goToEASiScoring()}
                    >
                      Head
                    </Button>
                    <Button onClick={() => this.goToUpperExt()} >
                      {studyId == 4 ? "Upper Limbs" : "Upper Extremity"}
                    </Button>
                    <Button onClick={() => this.goToLowerExt()}
                      className={selectedBodyPart === "Lower Ext" ? "active" : ""}>
                      {studyId == 4 ? "Lower Limbs" : "Lower Extremity"}
                    </Button>
                    <Button onClick={() => this.goToTrunk()}>Trunk</Button>
                    <Button onClick={() => this.goToReviewTotal()}>
                      Review Total Scores
                    </Button>
                    <Button onClick={() => this.goToGlobalAssessment()}>
                      Global Assessment
                    </Button>
                  </ButtonGroup>

                <Card
                  title={studyId === "4" && patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS" ? 
                    "PASI Lower Limbs" :
                    studyId === "4" ? "EASI Lower Limbs" :
                    "EASI Lower Extremity"
                  }
                  extra={
                    <Button onClick={() => this.goToPatientProgress()}>
                      Back
                    </Button>
                  }
                  style={{ marginBottom: "8px", padding: "0 16px" }}
                  className="patient-dairy-section"
                >
                  {/* Activity Content Section Start */}
                  <div className="dairy-content-section">
                    <div className="play-icon-container"></div>
                    <Spin spinning={loading}>
                      {
                        studyId === "1" ?
                        <div className="slide-content">
                          <Carousel
                            slidesPerRow={3}
                            arrows={true}
                            prevArrow={<LeftOutlined />}
                            nextArrow={<RightOutlined />}
                            className="images-slider"
                            //slidesPerRow={3}
                            swipeToSlide={true}
                            touchThreshold={20}
                            focusOnSelect={true}
                            centerMode={true}
                            ref={(carousel) => (this.nav = carousel)}
                            draggable={true}
                            autoplay={true}
                            vertical
                            effect="fade"
                            initialSlide={initialSlide}
                          >
                            {photoGallery &&
                              photoGallery.map((img) => {
                                return (
                                  <div>
                                    <img
                                      key={img.id}
                                      src={img.url}
                                      alt={img.desc}
                                      onClick={() => this.showModal(img)}
                                      loading="lazy"
                                    />
                                    <Typography.Text>{img.desc}</Typography.Text>
                                    <div>
                                      <Modal
                                        centered
                                        width={450}
                                        className="photo-zoom-popup"
                                        title={this.state.image.desc}
                                        visible={this.state.isModalVisible}
                                        onCancel={this.handleCancel}
                                        footer={[<></>]}
                                      >
                                        <TransformWrapper
                                          defaultScale={1}
                                          defaultPositionX={1}
                                          defaultPositionY={1}
                                        >
                                          {({ zoomIn, zoomOut }) => (
                                            <>
                                              <PlusCircleFilled
                                                size={30}
                                                onClick={zoomIn}
                                              />
                                              <MinusCircleFilled
                                                size={20}
                                                onClick={zoomOut}
                                              />
                                              <TransformComponent>
                                                <img
                                                  width={400}
                                                  src={this.state.image.url}
                                                  alt={img.desc}
                                                  loading="lazy"
                                                />
                                              </TransformComponent>
                                            </>
                                          )}
                                        </TransformWrapper>
                                      </Modal>
                                    </div>
                                  </div>
                                );
                              })}
                          </Carousel>
                        </div>
                        :
                        <div className="slide-content">
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModalOth(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  // width={450}
                                  // className="photo-zoom-popup"
                                  width={rotationAngleState === 90 || rotationAngleState === 270 ? 1200 : 450}
                                  className={rotationAngleState === 90 || rotationAngleState === 270 ? "photo-zoom-popup ninty" : "photo-zoom-popup"}
                                  // title={this.state.image.desc}
                                  title={"Week " +
                                    weekNumber +
                                    " " +
                                    "Lower Extremity images"}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                          photoGalleryByWeek.map((img, index) => (
                                            <div key={index}>
                                              <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                                {({ zoomIn, zoomOut }) => (
                                                  <>
                                                    <div style={{ textAlign: 'center' }}>
                                                      <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                      <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                      <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                      <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                    </div>
                                                    <TransformComponent>
                                                      <img
                                                        width={400}
                                                        src={img.url}
                                                        alt={img.desc}
                                                        loading="lazy"
                                                        style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                      />
                                                    </TransformComponent>
                                                  </>
                                                )}
                                              </TransformWrapper>
                                              <Typography.Text>{img.desc}</Typography.Text>
                                            </div>
                                          ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                          </div>
                      }
                    </Spin>
                    <Button
                      onClick={() => this.goToPhotoGallery()}
                      type="primary"
                      className="btn-sq"
                      style={{ marginBottom: 30 }}
                    >
                      View all photos
                    </Button>

                    <Spin spinning={loading}>
                    {easiScores && studyId == 1 ? (
                        <div className="review-data-section">
                          <Row gutter={16} className="data-header-row">
                            <Col xs={24} md={8}>
                              &nbsp;
                            </Col>
                            <Col xs={24} md={16}>
                              <div className="value-header">
                                <div className="value-header-col">None</div>
                                <div className="value-header-col">Mild</div>
                                <div className="value-header-col">Moderate</div>
                                <div className="value-header-col">Severe</div>
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16} className="data-content-row">
                            <Col xs={24} md={8}>
                              Erythema
                            </Col>
                            <Col xs={24} md={16}>
                              <Radio.Group
                                value={
                                  easiScores.erythema
                                    ? easiScores.erythema
                                    : ""
                                }
                                className="review-value-selection"
                              >
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      erythemaValue: 0,
                                      erythema: "None",
                                    })
                                  }
                                  key="ER1"
                                  value={"None"}
                                />
                                
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      erythemaValue: 1,
                                      erythema: "Mild",
                                    })
                                  }
                                  key="ER2"
                                  value={"Mild"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      erythemaValue: 2,
                                      erythema: "Moderate",
                                    })
                                  }
                                  key="ER3"
                                  value={"Moderate"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      erythemaValue: 3,
                                      erythema: "Severe",
                                    })
                                  }
                                  key="ER4"
                                  value={"Severe"}
                                />
                              </Radio.Group>
                            </Col>
                          </Row>
                          <Row gutter={16} className="data-content-row">
                            <Col xs={24} md={8}>
                              Edema/Population
                            </Col>
                            <Col xs={24} md={16}>
                              <Radio.Group
                                defaultValue={
                                  easiScores.edema ? easiScores.edema : "5"
                                }
                                className="review-value-selection"
                              >
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      edemaValue: 0,
                                      edema: "None",
                                    })
                                  }
                                  key="ER1"
                                  value={"None"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      edemaValue: 1,
                                      edema: "Mild",
                                    })
                                  }
                                  key="ER2"
                                  value={"Mild"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      edemaValue: 2,
                                      edema: "Moderate",
                                    })
                                  }
                                  key="ER3"
                                  value={"Moderate"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      edemaValue: 3,
                                      edema: "Severe",
                                    })
                                  }
                                  key="ER4"
                                  value={"Severe"}
                                />
                              </Radio.Group>
                            </Col>
                          </Row>
                          <Row gutter={16} className="data-content-row">
                            <Col xs={24} md={8}>
                              Excoriation
                            </Col>
                            <Col xs={24} md={16}>
                              <Radio.Group
                                defaultValue={
                                  easiScores.excoriation
                                    ? easiScores.excoriation
                                    : "5"
                                }
                                className="review-value-selection"
                              >
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      excoriationsValue: 0,
                                      excoriation: "None",
                                    })
                                  }
                                  key="ER1"
                                  value={"None"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      excoriationsValue: 1,
                                      excoriation: "Mild",
                                    })
                                  }
                                  key="ER2"
                                  value={"Mild"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      excoriationsValue: 2,
                                      excoriation: "Moderate",
                                    })
                                  }
                                  key="ER3"
                                  value={"Moderate"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      excoriationsValue: 3,
                                      excoriation: "Severe",
                                    })
                                  }
                                  key="ER4"
                                  value={"Severe"}
                                />
                              </Radio.Group>
                            </Col>
                          </Row>
                          <Row gutter={16} className="data-content-row">
                            <Col xs={24} md={8}>
                              Lichenification
                            </Col>
                            <Col xs={24} md={16}>
                              <Radio.Group
                                defaultValue={
                                  easiScores.ascoScope
                                    ? easiScores.ascoScope
                                    : "5"
                                }
                                className="review-value-selection"
                              >
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      ascoScopeValue: 0,
                                      ascoScope: "None",
                                    })
                                  }
                                  key="ER1"
                                  value={"None"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      ascoScopeValue: 1,
                                      ascoScope: "Mild",
                                    })
                                  }
                                  key="ER2"
                                  value={"Mild"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      ascoScopeValue: 2,
                                      ascoScope: "Moderate",
                                    })
                                  }
                                  key="ER3"
                                  value={"Moderate"}
                                />
                                <Radio
                                  onClick={() =>
                                    this.setState({
                                      ascoScopeValue: 3,
                                      ascoScope: "Severe",
                                    })
                                  }
                                  key="ER4"
                                  value={"Severe"}
                                />
                              </Radio.Group>
                            </Col>
                          </Row>
                          <Button
                            onClick={() => this.redirect(reviewStandards)}
                            type="primary"
                            className="btn-sq"
                            style={{ marginBottom: 30 }}
                          >
                            Review Standards
                          </Button>
                        </div>
                      ) : easiScores && studyId == 2 ?(
                        <div className="review-data-section">
                          <Row gutter={16} className="data-header-row">
                            <Col xs={24} md={8}>
                            </Col>
                            <Col xs={24} md={16}>
                              <div className="value-header">
                              <div className="value-header-col">None</div>
                                <div className="value-header-col">Mild</div>
                                <div className="value-header-col">Mild Moderate</div>
                                <div className="value-header-col">Moderate</div>
                                <div className="value-header-col">Moderate Severe</div>
                                <div className="value-header-col">Severe</div>
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16} className="data-content-row">
                            <Col xs={24} md={8}>
                              Erythema
                            </Col>
                            <Col xs={24} md={16}>
                              <Radio.Group
                                // defaultValue={
                                //   easiScores.erythema
                                //     ? easiScores.erythema
                                //     : this.state.localErythmaValue
                                // }
                                value={
                                  // selectedErythma ? 
                                  // selectedErythma : 
                                  // easiScores && 
                                  // easiScores.erythema ?
                                  // easiScores.erythema :
                                  // "None"
                                  !this.state.erythema ? 
                                  selectedErythma || 
                                  (!selectedErythma && easiScores && easiScores.erythema) :
                                  this.state.erythema
                                }
                                className="review-value-selection"
                              >
                                <Radio
                                  key="ER1"
                                  value={"None"}
                                  onChange={() => {
                                      const value = "None";
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 0,
                                        erythema: "None",
                                      });
                                    }}
                                />
                                
                                <Radio
                                  key="ER2"
                                  value={"Mild"}
                                  onChange={() =>{
                                    const value = "Mild";
                                    this.handleErythemaValueChange(value);
                                    this.setState({
                                      erythemaValue: 1,
                                      erythema: "Mild",
                                    });
                                  }}
                                />
                                <Radio
                                  key="ER3"
                                  value={"Mild_Moderate"}
                                  onChange={() =>{
                                    const value = "Mild_Moderate";
                                    this.handleErythemaValueChange(value);
                                    this.setState({
                                      erythemaValue: 1.5,
                                      erythema: "Mild_Moderate",
                                    });
                                  }}
                                />
                                <Radio
                                  key="ER4"
                                  value={"Moderate"}
                                  onChange={() =>{
                                    const value = "Moderate"
                                    this.handleErythemaValueChange(value);
                                    this.setState({
                                      erythemaValue: 2,
                                      erythema: "Moderate",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER5"
                                  value={"Moderate_Severe"}
                                  onChange={() =>{
                                    const value = "Moderate_Severe"
                                    this.handleErythemaValueChange(value);
                                    this.setState({
                                      erythemaValue: 2.5,
                                      erythema: "Moderate_Severe",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER6"
                                  value={"Severe"}
                                  onChange={() =>{
                                    const value = "Severe"
                                    this.handleErythemaValueChange(value);
                                    this.setState({
                                      erythemaValue: 3,
                                      erythema: "Severe",
                                    })
                                  }}
                                />
                              </Radio.Group>
                            </Col>
                          </Row>
                          <Row gutter={16} className="data-content-row">
                            <Col xs={24} md={8}>
                              Induration/Papulation
                            </Col>
                            <Col xs={24} md={16}>
                              <Radio.Group
                                value={
                                  // selectedInduration ?
                                  // selectedInduration :
                                  // easiScores && 
                                  // easiScores.induration ?
                                  // easiScores.induration :
                                  // "None"
                                  !this.state.induration ?
                                  selectedInduration || 
                                  (!selectedInduration && easiScores && easiScores.induration) :
                                  this.state.induration
                                }
                                className="review-value-selection"
                              >
                                <Radio
                                  key="ER1"
                                  value={"None"}
                                  onChange={() =>{
                                    const value = "None"
                                    this.handleIndurationValueChange(value)
                                    this.setState({
                                      indurationValue: 0,
                                      induration: "None",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER2"
                                  value={"Mild"}
                                  onChange={() =>{
                                    const value = "Mild"
                                    this.handleIndurationValueChange(value)
                                    this.setState({
                                      indurationValue: 1,
                                      induration: "Mild",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER3"
                                  value={"Mild_Moderate"}
                                  onChange={() =>{
                                    const value = "Mild_Moderate"
                                    this.handleIndurationValueChange(value)
                                    this.setState({
                                      indurationValue: 1.5,
                                      induration: "Mild_Moderate",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER4"
                                  value={"Moderate"}
                                  onChange={() =>{
                                    const value = "Moderate"
                                    this.handleIndurationValueChange(value)
                                    this.setState({
                                      indurationValue: 2,
                                      induration: "Moderate",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER5"
                                  value={"Moderate_Severe"}
                                  onChange={() =>{
                                    const value = "Moderate_Severe"
                                    this.handleIndurationValueChange(value)
                                    this.setState({
                                      indurationValue: 2.5,
                                      induration: "Moderate_Severe",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER6"
                                  value={"Severe"}
                                  onChange={() =>{
                                    const value = "Severe"
                                    this.handleIndurationValueChange(value)
                                    this.setState({
                                      indurationValue: 3,
                                      induration: "Severe",
                                    })
                                  }}
                                />
                              </Radio.Group>
                            </Col>
                          </Row>
                          <Row gutter={16} className="data-content-row">
                            <Col xs={24} md={8}>
                              Excoriation
                            </Col>
                            <Col xs={24} md={16}>
                              <Radio.Group
                                value={
                                  // selectedExcoriation ?
                                  // selectedExcoriation :
                                  // easiScores && 
                                  // easiScores.excoriation ?
                                  // easiScores.excoriation :
                                  // "None"
                                  !this.state.excoriation ?
                                  selectedExcoriation || 
                                  (!selectedExcoriation && easiScores && easiScores.excoriation) :
                                  this.state.excoriation
                                }
                                className="review-value-selection"
                              >
                                <Radio
                                  key="ER1"
                                  value={"None"}
                                  onChange={() =>{
                                    const value = "None"
                                    this.handleExcoriationValueChange(value)
                                    this.setState({
                                      excoriationsValue: 0,
                                      excoriation: "None",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER2"
                                  value={"Mild"}
                                  onChange={() =>{
                                    const value = "Mild"
                                    this.handleExcoriationValueChange(value)
                                    this.setState({
                                      excoriationsValue: 1,
                                      excoriation: "Mild",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER3"
                                  value={"Mild_Moderate"}
                                  onChange={() =>{
                                    const value = "Mild_Moderate"
                                    this.handleExcoriationValueChange(value)
                                    this.setState({
                                      excoriationsValue: 1.5,
                                      excoriation: "Mild_Moderate",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER4"
                                  value={"Moderate"}
                                  onChange={() =>{
                                    const value = "Moderate"
                                    this.handleExcoriationValueChange(value)
                                    this.setState({
                                      excoriationsValue: 2,
                                      excoriation: "Moderate",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER5"
                                  value={"Moderate_Severe"}
                                  onChange={() =>{
                                    const value = "Moderate_Severe"
                                    this.handleExcoriationValueChange(value)
                                    this.setState({
                                      excoriationsValue: 2.5,
                                      excoriation: "Moderate_Severe",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER6"
                                  value={"Severe"}
                                  onChange={() =>{
                                    const value = "Severe"
                                    this.handleExcoriationValueChange(value)
                                    this.setState({
                                      excoriationsValue: 3,
                                      excoriation: "Severe",
                                    })
                                  }}
                                />
                              </Radio.Group>
                            </Col>
                          </Row>
                          <Row gutter={16} className="data-content-row">
                            <Col xs={24} md={8}>
                              Lichenification
                            </Col>
                            <Col xs={24} md={16}>
                              <Radio.Group
                                value={
                                  // selectedLichenification ?
                                  // selectedLichenification :
                                  // easiScores && easiScores.lichenification ?
                                  // easiScores.lichenification
                                  // : "None"
                                  !this.state.lichenification ?
                                  selectedLichenification || 
                                  (!selectedLichenification && easiScores && easiScores.lichenification) :
                                  this.state.lichenification
                                }
                                className="review-value-selection"
                              >
                                <Radio
                                  key="ER1"
                                  value={"None"}
                                  onChange={() =>{
                                    const value = "None"
                                    this.handleLichenificationValueChange(value)
                                    this.setState({
                                      lichenificationValue: 0,
                                      lichenification: "None",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER2"
                                  value={"Mild"}
                                  onChange={() =>{
                                    const value = "Mild"
                                    this.handleLichenificationValueChange(value)
                                    this.setState({
                                      lichenificationValue: 1,
                                      lichenification: "Mild",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER3"
                                  value={"Mild_Moderate"}
                                  onChange={() =>{
                                    const value = "Mild_Moderate"
                                    this.handleLichenificationValueChange(value)
                                    this.setState({
                                      lichenificationValue: 1.5,
                                      lichenification: "Mild_Moderate",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER4"
                                  value={"Moderate"}
                                  onChange={() =>{
                                    const value = "Moderate"
                                    this.handleLichenificationValueChange(value)
                                    this.setState({
                                      lichenificationValue: 2,
                                      lichenification: "Moderate",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER5"
                                  value={"Moderate_Severe"}
                                  onChange={() =>{
                                    const value = "Moderate_Severe"
                                    this.handleLichenificationValueChange(value)
                                    this.setState({
                                      lichenificationValue: 2.5,
                                      lichenification: "Moderate_Severe",
                                    })
                                  }}
                                />
                                <Radio
                                  key="ER6"
                                  value={"Severe"}
                                  onChange={() =>{
                                    const value = "Severe"
                                    this.handleLichenificationValueChange(value)
                                    this.setState({
                                      lichenificationValue: 3,
                                      lichenification: "Severe",
                                    })
                                  }}
                                />
                              </Radio.Group>
                            </Col>
                          </Row>
                          {/* <Button
                            onClick={() => this.redirect(reviewStandards)}
                            type="primary"
                            className="btn-sq"
                            style={{ marginBottom: 30 }}
                          >
                            Review Standards
                          </Button> */}
                        </div>
                      ): (
                        <div className="review-data-section">
                          {
                            patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS" ?<>
                            <Row gutter={16} className="data-header-row">
                              <Col xs={24} md={8}>
                                &nbsp;
                              </Col>
                              <Col xs={24} md={16}>
                                <div className="value-header">
                                  <div className="value-header-col">None</div>
                                  <div className="value-header-col">Slight</div>
                                  <div className="value-header-col">Moderate</div>
                                  <div className="value-header-col">Severe</div>
                                  <div className="value-header-col">Very Severe</div>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Erythema
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  // value={easiScores && easiScores.erythema ? easiScores.erythema : localErythmaValue}
                                  value={this.state.erythema}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() => {
                                        const value = "None";
                                        this.handleErythemaValueChange(value);
                                        this.setState({
                                          erythemaValue: 0,
                                          erythema: "None",
                                        });
                                      }}
                                  />
                                  
                                  <Radio
                                    key="ER2"
                                    value={"Slight"}
                                    onChange={() =>{
                                      const value = "Slight";
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 1,
                                        erythema: "Slight",
                                      });
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Moderate"}
                                    onChange={() =>{
                                      const value = "Moderate"
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 2,
                                        erythema: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Severe"}
                                    onChange={() =>{
                                      const value = "Severe"
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 3,
                                        erythema: "Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Very_Severe"}
                                    onChange={() =>{
                                      const value = "Very_Severe"
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 4,
                                        erythema: "Very_Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Induration/Thickness
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  value={this.state.induration}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() =>{
                                      const value = "None"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 0,
                                        induration: "None",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER2"
                                    value={"Slight"}
                                    onChange={() =>{
                                      const value = "Slight"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 1,
                                        induration: "Slight",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Moderate"}
                                    onChange={() =>{
                                      const value = "Moderate"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 2,
                                        induration: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Severe"}
                                    onChange={() =>{
                                      const value = "Severe"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 3,
                                        induration: "Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Very_Severe"}
                                    onChange={() =>{
                                      const value = "Very_Severe"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 4,
                                        induration: "Very_Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Scaling
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  value={this.state.scaling}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() =>{
                                      const value = "None"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 0,
                                        scaling: "None",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER2"
                                    value={"Slight"}
                                    onChange={() =>{
                                      const value = "Slight"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 1,
                                        scaling: "Slight",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Moderate"}
                                    onChange={() =>{
                                      const value = "Moderate"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 2,
                                        scaling: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Severe"}
                                    onChange={() =>{
                                      const value = "Severe"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 3,
                                        scaling: "Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Very_Severe"}
                                    onChange={() =>{
                                      const value = "Very_Severe"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 4,
                                        scaling: "Very_Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>  </>: 
                            <>
                            <Row gutter={16} className="data-header-row">
                              <Col xs={24} md={8}>
                                &nbsp;
                              </Col>
                              <Col xs={24} md={16}>
                                <div className="value-header">
                                  <div className="value-header-col">None</div>
                                  <div className="value-header-col">Mild</div>
                                  <div className="value-header-col">Mild Moderate</div>
                                  <div className="value-header-col">Moderate</div>
                                  <div className="value-header-col">Moderate Severe</div>
                                  <div className="value-header-col">Severe</div>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Erythema
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  value={this.state.erythema}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() => {
                                        const value = "None";
                                        this.handleErythemaValueChange(value);
                                        this.setState({
                                          erythemaValue: 0,
                                          erythema: "None",
                                        });
                                      }}
                                  />
                                  
                                  <Radio
                                    key="ER2"
                                    value={"Mild"}
                                    onChange={() =>{
                                      const value = "Mild";
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 1,
                                        erythema: "Mild",
                                      });
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Mild_Moderate"}
                                    onChange={() =>{
                                      const value = "Mild_Moderate";
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 1.5,
                                        erythema: "Mild_Moderate",
                                      });
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Moderate"}
                                    onChange={() =>{
                                      const value = "Moderate"
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 2,
                                        erythema: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Moderate_Severe"}
                                    onChange={() =>{
                                      const value = "Moderate_Severe"
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 2.5,
                                        erythema: "Moderate_Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER6"
                                    value={"Severe"}
                                    onChange={() =>{
                                      const value = "Severe"
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 3,
                                        erythema: "Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Induration/Papulation
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  value={this.state.induration}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() =>{
                                      const value = "None"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 0,
                                        induration: "None",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER2"
                                    value={"Mild"}
                                    onChange={() =>{
                                      const value = "Mild"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 1,
                                        induration: "Mild",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Mild_Moderate"}
                                    onChange={() =>{
                                      const value = "Mild_Moderate"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 1.5,
                                        induration: "Mild_Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Moderate"}
                                    onChange={() =>{
                                      const value = "Moderate"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 2,
                                        induration: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Moderate_Severe"}
                                    onChange={() =>{
                                      const value = "Moderate_Severe"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 2.5,
                                        induration: "Moderate_Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER6"
                                    value={"Severe"}
                                    onChange={() =>{
                                      const value = "Severe"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 3,
                                        induration: "Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Excoriation
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  value={this.state.excoriation}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() =>{
                                      const value = "None"
                                      this.handleExcoriationValueChange(value)
                                      this.setState({
                                        excoriationsValue: 0,
                                        excoriation: "None",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER2"
                                    value={"Mild"}
                                    onChange={() =>{
                                      const value = "Mild"
                                      this.handleExcoriationValueChange(value)
                                      this.setState({
                                        excoriationsValue: 1,
                                        excoriation: "Mild",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Mild_Moderate"}
                                    onChange={() =>{
                                      const value = "Mild_Moderate"
                                      this.handleExcoriationValueChange(value)
                                      this.setState({
                                        excoriationsValue: 1.5,
                                        excoriation: "Mild_Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Moderate"}
                                    onChange={() =>{
                                      const value = "Moderate"
                                      this.handleExcoriationValueChange(value)
                                      this.setState({
                                        excoriationsValue: 2,
                                        excoriation: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Moderate_Severe"}
                                    onChange={() =>{
                                      const value = "Moderate_Severe"
                                      this.handleExcoriationValueChange(value)
                                      this.setState({
                                        excoriationsValue: 2.5,
                                        excoriation: "Moderate_Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER6"
                                    value={"Severe"}
                                    onChange={() =>{
                                      const value = "Severe"
                                      this.handleExcoriationValueChange(value)
                                      this.setState({
                                        excoriationsValue: 3,
                                        excoriation: "Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Lichenification
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  value={this.state.lichenification}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() =>{
                                      const value = "None"
                                      this.handleLichenificationValueChange(value)
                                      this.setState({
                                        lichenificationValue: 0,
                                        lichenification: "None",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER2"
                                    value={"Mild"}
                                    onChange={() =>{
                                      const value = "Mild"
                                      this.handleLichenificationValueChange(value)
                                      this.setState({
                                        lichenificationValue: 1,
                                        lichenification: "Mild",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Mild_Moderate"}
                                    onChange={() =>{
                                      const value = "Mild_Moderate"
                                      this.handleLichenificationValueChange(value)
                                      this.setState({
                                        lichenificationValue: 1.5,
                                        lichenification: "Mild_Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Moderate"}
                                    onChange={() =>{
                                      const value = "Moderate"
                                      this.handleLichenificationValueChange(value)
                                      this.setState({
                                        lichenificationValue: 2,
                                        lichenification: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Moderate_Severe"}
                                    onChange={() =>{
                                      const value = "Moderate_Severe"
                                      this.handleLichenificationValueChange(value)
                                      this.setState({
                                        lichenificationValue: 2.5,
                                        lichenification: "Moderate_Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER6"
                                    value={"Severe"}
                                    onChange={() =>{
                                      const value = "Severe"
                                      this.handleLichenificationValueChange(value)
                                      this.setState({
                                        lichenificationValue: 3,
                                        lichenification: "Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>
                            </>
                          }
                        </div>
                      )}
                    </Spin>
                    <Spin spinning={submitAreaScore}>
                      {patientSelfEstimation ? (
                        <div className="area-score-section">
                          <h5>Area Score</h5>
                          {studyId == 1 ? 
                          (
                            <Radio.Group
                            defaultValue={
                              patientSelfEstimation.rangeMaxVal
                                ? patientSelfEstimation.rangeMaxVal
                                : "11"
                            }
                            className="review-value-selection"
                          >
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "0",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema"
                              value={"0"}
                            >
                              No eczema on face, neck, and scalp
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "-1",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema1"
                              value={"-1"}
                            >
                              {"<1 palm"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "1",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema2"
                              value={"1"}
                            >
                              {"1 palm"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "2",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema3"
                              value={"2"}
                            >
                              {"2 palms"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "3",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema4"
                              value={"3"}
                            >
                              {"3 palms"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "4",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema5"
                              value={"4"}
                            >
                              {"4 palms"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "5",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema6"
                              value={"5"}
                            >
                              {"5 palms"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "6",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema7"
                              value={"6"}
                            >
                              {"6 palms"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "7",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema8"
                              value={"7"}
                            >
                              {"7 palms"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "8",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema9"
                              value={"8"}
                            >
                              {"8 palms"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "9",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema10"
                              value={"9"}
                            >
                              {"9 palms"}
                            </Radio>
                            <Radio
                              onClick={() =>
                                this.updateScores(
                                  "10",
                                  patientSelfEstimation.selfEstimationId
                                )
                              }
                              key="no Eczema11"
                              value={"10"}
                            >
                              {"10 palms 0r more"}
                            </Radio>
                          </Radio.Group>
                          ) : (
                            <Radio.Group
                              value={this.state.areaScoreValue
                                  ? 
                                this.state.areaScoreValue.toString() :
                                this.state.areaScoreValue == 0 ? "0" :""
                                }
                              onChange={this.onChangeAreaScore}
                              className="review-value-selection"
                              >
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 0,
                                  })
                                }
                                key="no Eczema"
                                value={"0"}
                              >
                                {"0%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 1,
                                  })
                                }
                                key="no Eczema1"
                                value={"1"}
                              >
                                {"1-9%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 2,
                                  })
                                }
                                key="no Eczema2"
                                value={"2"}
                              >
                                {"10-29%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 3,
                                  })
                                }
                                key="no Eczema3"
                                value={"3"}
                              >
                                {"30-49%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 5,
                                  })
                                }
                                key="no Eczema5"
                                value={"5"}
                              >
                                {"50-69%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 7,
                                  })
                                }
                                key="no Eczema7"
                                value={"7"}
                              >
                                {"70-89%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 9,
                                  })
                                }
                                key="no Eczema9"
                                value={"9"}
                              >
                                {"90-100%"}
                              </Radio>
                            </Radio.Group>
                          )
                          }
                          
                          {/* <p>
                            <i>
                              Patient's self estimation for head % automatically
                              checked, but can be changed
                            </i>
                          </p> */}
                        </div>
                      ) : (
                        <div className="area-score-section">
                          <h5>Area Score</h5>
                          <Radio.Group
                              value={this.state.areaScoreValue ? this.state.areaScoreValue.toString() :
                                easiScores && easiScores.areaScore ? easiScores.areaScore.toString() : 
                                (easiScores && easiScores.areaScore === 0 || easiScores && easiScores.areaScore === "0") ? "0" : ""}
                              onChange={this.onChangeAreaScore}
                              className="review-value-selection"
                              >
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 0,
                                  })
                                }
                                key="no Eczema"
                                value={"0"}
                              >
                                {"0%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 1,
                                  })
                                }
                                key="no Eczema1"
                                value={"1"}
                              >
                                {"1-9%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 2,
                                  })
                                }
                                key="no Eczema2"
                                value={"2"}
                              >
                                {"10-29%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 3,
                                  })
                                }
                                key="no Eczema3"
                                value={"3"}
                              >
                                {"30-49%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 4,
                                  })
                                }
                                key="no Eczema4"
                                value={"4"}
                              >
                                {"50-69%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 5,
                                  })
                                }
                                key="no Eczema5"
                                value={"5"}
                              >
                                {"70-89%"}
                              </Radio>
                              <Radio
                                onChange={() =>
                                  this.setState({
                                    areaScoreValue: 6,
                                  })
                                }
                                key="no Eczema6"
                                value={"6"}
                              >
                                {"90-100%"}
                              </Radio>
                            </Radio.Group>
                        </div>
                      )}
                    </Spin>

                    <Spin spinning={submitted}>
                      {/*(patientSelfEstimation && patientSelfEstimation.rangeMaxVal && edemaValue && easiScores && excoriationsValue && ascoScopeValue) || easiScores ? <Form name="easi-head-form" className="easi-head-form" layout="inline" onFinish={this.saveEASIHeadScores}>
                                                        <Form.Item
                                                            name="headTotal"
                                                            label="EASI lowerExt total"
                                                            >
                                                            <Input defaultValue = {easiScores ?easiScores.totalScore:(Math.round((((edemaValue+excoriationsValue+erythemaValue+ascoScopeValue)*patientSelfEstimation.rangeMaxVal*0.1) * 1000)/10)/100).toFixed(2)} disabled  />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button
                                                                type="primary"
                                                                htmlType="submit"
                                                                className="btn-sq">
                                                                Submit
                                                        </Button>
                                                        </Form.Item>
                                                                    </Form>:""*/}
                      {easiScores && typeof easiScores.totalScore !== 'undefined' ? (
                        <Form
                          key={easiScores.totalScore}
                          name="easi-head-form"
                          className="easi-head-form"
                          layout="inline"
                          onFinish={studyId == "4" ? this.savePasiScore : this.saveEASIHeadScores}
                        >
                          <Form.Item
                            name="headTotal"
                            label={
                              studyId === "4" && patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS" ? 
                              "PASI Lower Limbs total" :
                              studyId === "4" ? "EASI Lower Limbs total" :
                              "EASI lowerExt total"
                            }
                          >
                            <Input
                              defaultValue={easiScores && easiScores.totalScore}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="btn-sq"
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </Form>
                      ) : (
                        <Form
                          name="easi-head-form"
                          className="easi-head-form"
                          layout="inline"
                          onFinish={studyId == "4" ? this.savePasiScore : this.saveEASIHeadScores}
                        >
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="btn-sq"
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </Form>
                      )}
                    </Spin>
                  </div>

                  {/* /Activity Content Section End */}
                </Card>

                <ul className="link-list-box" style={{ display: "block" }}>
                  {
                    studyId == 1 ?
                    <li>
                      <div onClick={() => this.goToChat()} class="link-box">
                        <ChatIcon />
                        <h4>Chat</h4>
                      </div>
                    </li>
                    :
                    ""
                  }
                  <li>
                    <div
                      onClick={() => this.goToEASiScoring()}
                      className={
                        selectedCol === "survey"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <ServeyIcon />
                      <h4>Surveys &amp; Scores</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToDiary("diary")}
                      className={
                        selectedCol === "diary"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <DairyIcon />
                      <h4>Diary</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToPhotoGallery("gallery")}
                      className={
                        selectedCol === "gallery"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <PhotogalleryIcon />
                      <h4>Photo Gallery </h4>
                    </div>
                  </li>
                  <li>
                    <div
                      className={
                        selectedCol === "adverseEvents"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                      onClick={() => this.goToAdverseEvents("adverse")}
                    >
                      <AdverseEventIcon />
                      <h4>Adverse Events</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToActivityData("activity")}
                      class="link-box"
                    >
                      <DataIcon />
                      <h4>Activity Data</h4>
                    </div>
                  </li>
                  {
                    studyId == 1 ?
                    <li>
                      <div
                        onClick={() => this.goToLab("lab")}
                        class="link-box"
                      >
                        <LabIcon />
                        <h4>Lab</h4>
                      </div>
                    </li>
                    :
                    ""
                  }
                  {
                    studyId == 4 && (patientDataHealth && patientDataHealth.healthCondition !== "HEALTHY_CONTROL") ?
                    <li>
                      <div
                        onClick={() => this.goToFlares()}
                        class="link-box extra"
                      >
                        <AuditOutlined style={{fontSize: "35px"}}/>
                        <h4>Flares</h4>
                      </div>
                    </li>
                    : ""
                  }
                  {
                    studyId === "4" ?
                    <li>
                      <div
                        onClick={() => this.goToNewMed()}
                        class="link-box extra"
                      >
                        <MedicationIcon/>
                        <h4>Medication</h4>
                      </div>
                    </li>
                    : ""
                  }
                </ul>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    patientSelfEstimation: state.patient.patientSelfEstimation,
    patientSelfEstimationJanssen: state.patient.patientSelfEstimationJanssen,
    easiScores: state.patient.easiScores,
    patientGalleryByWeek: state.patient.patientGalleryByWeek,
    photoGallery: state.patient.photoGallery,
    photoGalleryByWeek: state.patient.photoGalleryByWeek,
    easiHeadScores: state.easiScore.easiHeadScores,
    janssenImagesScore: state.patient.janssenImagesScore,
    updatePatientSelfEstimation: state.patient.updatePatientSelfEstimation,
    weekByParts: state.patient.weekByParts
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...StudyActions, ...EasiScoreActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    EASILowerExt
  )
);
