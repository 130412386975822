import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";
import { SearchOutlined, AuditOutlined } from "@ant-design/icons";

// import axios from "../../services/axios-service-auth";
import * as LabActions from "../../redux/actions/lab-actions";
import "react-h5-audio-player/lib/styles.css";
// import { UploadOutlined } from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Input,
  Table,
  Space,
  // Upload,
  Image,
  Spin,
  Card,
  Button,
  Form,
  PageHeader,
  DatePicker,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import { LabSvg } from "../shared/svg/labicon";

const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);

class Lab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      formKey: 0,
      submitted: false,
      isLoading: true,
      selectItemId: "",
      selectedCol: "",
      isupLoading: false,
      tests: undefined,
      defaultDate: undefined,
      multipartFile: undefined,
      isupLoading: false,
      key: 1
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    // await this.props.getPatientById(patientId);
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(patientId);
    } else if (studyId == 4){
      await this.props.getPatientByIdNih(patientId);
    } else {
      await this.props.getPatientById(patientId);
    }
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({ loading: false });
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }

    await this.props.getLabTests(patientId);
    if (this.props.testList) {
      this.setState({ tests: this.props.testList, isLoading: false });
    }
  }


  // async componentWillReceiveProps(nextProps) {
  //   console.log("this.props.testList--",this.props.testList);
  //   console.log("nextProps.testList--",nextProps.testList);
  //     // debugger
  //     if (this.state.testList !== nextProps.testList) {
  //       console.log("list--",this.props.testList);
  //         debugger
  //       this.setState({ testList: nextProps.testList});
  //     }
  //   }

  onFileChange = async () => {
    if (this.state.multipartFile && this.state.defaultDate) {
      var formData = new FormData();
      this.setState({ isupLoading: true });
      formData.append("multipartFile", this.state.multipartFile);
      formData.append("date", this.state.defaultDate);
      formData.append("patientId", this.props.match.params.id);
      await this.props.uploadLabTest(formData);
      if (this.props.uploadTest) {
        this.setState({ isupLoading: false, defaultDate: undefined, multipartFile: undefined })
        notifyUser("Test Uploaded successfully", "success");

        await this.props.getLabTests(this.props.match.params.id);
        if (this.props.testList) {
          this.setState({ tests: this.props.testList, isLoading: false, key: this.state.key + 1 });
        }
      }
    } else {
      this.setState({ isupLoading: false });
      notifyUser("Please select default test date", "error");
    }

  };

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  goToStudyDrugLog = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/study-drug-log/" + patientId);
  };

  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  render() {
    const { submitted, selectItemId, selectedCol, isupLoading, isLoading } =
      this.state;
    const handleDateSelect = (event) => {
      this.setState({ defaultDate: moment(event).format("x") });
    };
    const handleFileSelect = (event) => {
      this.setState({ multipartFile: event.target.files[0] });
    };
    const studyId = localStorage.getItem("studyId");

    const columns = [
      {
        title: "Uploaded By",
        dataIndex: "uploadedByName",
        key: "uploadedByName",
        // sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy),
        width: "25%",
        ...this.getColumnSearchProps("uploadedByName"),
      },
      {
        title: "Default Date",
        dataIndex: "date",
        width: "25%",
        render: (_, record) =>
          this.state.tests ? (
            <div>{moment(record.date).format(dateFormathh)}</div>
          ) : null,
      },
      {
        title: "File Name",
        dataIndex: "fileName",
        render: (_, record) =>
          this.state.tests ? (
            <div>
              {record.fileName ? (
                <Link to={{ pathname: record.fileName }} target="_blank" >
                  {record.fileName.toString().slice(record.fileName.toString().lastIndexOf('/') + 1)}   {/*showing only filename */}
                </Link>)
                : ""}
              {/* <Image
                width={200}
                src={record.fileName}
              /> */}
            </div>
          ) : null,
        //   sorter: (a, b) => a.email.localeCompare(b.email),
        // ...this.getColumnSearchProps("fileName"),
      },
      {
        title: "Uploaded At",
        dataIndex: "uploadedAt",
        key: "uploadedAt",
        width: "20%",
        //  sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
        ...this.getColumnSearchProps("uploadedAt"),
      },
    ];

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                this.state.data &&
                  this.state.data != null &&
                  this.state.data.title
                  ? this.state.data.title
                  : "Eczema Tracking Study"
              }
            ></PageHeader>
            <Spin spinning={submitted}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={6} xl={6}>
                    <div className="white-box user-section">
                      <div className="user-profile">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <Avatar size={84}>
                            {this.props.patientData &&
                              this.props.patientData[0].firstName
                                .toUpperCase()
                                .charAt(0) +
                              this.props.patientData[0].lastName
                                .toUpperCase()
                                .charAt(0)}
                          </Avatar>
                        </Link>
                        <div className="profile-details">
                          <Link to={"/patient-progress/" + this.props.match.params.id} >
                            <h3>
                              {this.props.patientData &&
                                this.props.patientData[0].firstName +
                                " " +
                                this.props.patientData[0].lastName}
                            </h3>
                          </Link>
                          {
                            studyId === 1 ?
                            <button
                            className="btn"
                            onClick={() => this.goToChat()}
                          >
                            Chat Now
                          </button> :
                          ""
                          }
                        </div>
                      </div>
                      <div className="user-details">
                        <List
                          style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={(patient) => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Study:
                                </Typography.Text>{" "}
                                {patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Email:
                                </Typography.Text>{" "}
                                {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Date of Birth:
                                </Typography.Text>{" "}
                                {patient.dateOfBirth
                                  ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "MM/DD/YYYY"
                                  )
                                  : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Age:
                                </Typography.Text>{" "}
                                {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Phone Number:
                                </Typography.Text>{" "}
                                {"(" +
                                  patient.phoneCode +
                                  ") " +
                                  patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Member Status:
                                </Typography.Text>{" "}
                                {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Registered Date:
                                </Typography.Text>{" "}
                                {patient.userStudyCreatedDate
                                  ? moment(patient.userStudyCreatedDate).format(
                                    dateFormathh
                                  )
                                  : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={18} xl={18}>
                    <Card
                      title={"Lab"}
                      extra={
                        <Button onClick={() => this.goToPatientProgress()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="patient-dairy-section lab-table-sec"
                    >
                      <div>
                        <Row gutter={0}>
                          <Col xs={24} sm={24} md={24} xl={24}>
                            {/* Lab upload form START */}
                            <Form className="login-form lab-login-form"
                              key={this.state.key}
                            >
                              <Row>
                                <Col xs={24} sm={12} md={12} xl={12}>
                                  <Form.Item
                                    name="defaultDate"
                                    label="Default Laboratary Test Date"
                                  >
                                    <DatePicker
                                      // showTime
                                      format="YYYY-MM-DD HH:mm:ss"
                                      onChange={handleDateSelect}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={12} xl={12}>
                                  <Spin spinning={isupLoading}>
                                    {" "}
                                    <Form.Item className="file-submit-section">
                                      <input
                                        style={{ width: "230px" }}
                                        type="file"
                                        onChange={handleFileSelect}
                                      />
                                      <input
                                        type="submit"
                                        value="Save"
                                        onClick={this.onFileChange}
                                      />
                                    </Form.Item>
                                  </Spin>
                                </Col>
                              </Row>
                            </Form>
                            {/* Lab upload form END */}
                          </Col>
                        </Row>
                        <Row>
                          <Spin spinning={isLoading}>
                            {this.state.tests ? (
                              <Table
                                className="approved-patients-table"
                                columns={columns}
                                bordered
                                dataSource={this.state.tests}
                              />
                            ) : (
                              ""
                            )}
                          </Spin>
                        </Row>
                      </div>
                    </Card>

                    <ul className="link-list-box" style={{ display: "block" }}>
                      <li>
                        <div onClick={() => this.goToChat()} class="link-box">
                          <ChatIcon />
                          <h4>Chat</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToAdverseEvents("adverse")}
                          className={
                            selectedCol === "adverseEvents"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToStudyDrugLog("log")}
                          class="link-box"
                        >
                          <StudyDrugLogIcon />
                          <h4>Study Drug Log</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToLab("lab")}
                          class="link-box"
                        >
                          <LabIcon />
                          <h4>Lab</h4>
                        </div>
                      </li>
                      {
                        studyId == 4 ?
                        <li>
                        <div
                          onClick={() => this.goToFlares()}
                          class="link-box"
                        >
                          <AuditOutlined style={{fontSize: "35px"}}/>
                          <h4>Flares</h4>
                        </div>
                      </li>
                      : ""
                      }
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    uploadTest: state.labData.uploadFile,
    testList: state.labData.testList,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...StudyActions, ...LabActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Lab)
);
